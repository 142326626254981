import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {originalOrder} from "../../../../../../_util/utils";
import {ProjectCustomColors, ProjectCustomStatuses} from "../../../../../../_models/project";
import {Floor} from "../../../../../../_models/floor";

@Component({
  selector: 'app-project-status-selector',
  templateUrl: './project-status-selector.component.html',
  styleUrls: ['./project-status-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProjectStatusSelectorComponent),
      multi: true
    }
  ]
})
export class ProjectStatusSelectorComponent {
  @ViewChild('input') inputRef: ElementRef;
  @Input() isClearFieldBtn = false;
  @Input() placeholder: string;
  @Input() noOptionFind: string;

  @Input()
  set optionsList(value: ProjectCustomStatuses[]) {
    if(value) {
      this._statuses = value
      this.filteredElementList = [...value]
    }
  }
  get optionsList() {
    return this._statuses
  }
  @Input()
  set selectedOption(value: ProjectCustomStatuses) {
    this._selectedOption = value
  }

  get selectedOption() {
    return this._selectedOption;
  }
  @Output() changeEl: EventEmitter<any> = new EventEmitter<any>()
  @Output() close: EventEmitter<any> = new EventEmitter<any>()

  constructor() {
  }


  private _statuses: ProjectCustomStatuses[] = []
  private _selectedOption: ProjectCustomStatuses;
  inputValue: string | null;
  filteredElementList: any[] = [];
  filterListTimeout: any;

  writeValue(item: ProjectCustomStatuses | Floor): void {
    if (!item) return;
    this.selectedOption = this.optionsList.find((el: ProjectCustomStatuses | Floor) => el.id === item.id)

    setTimeout(() => {
      this.onChange(this.selectedOption);
    })
  }

  onChange: any = () => {
  }
  onTouch: any = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  searchEl(event: any) {
    this.filteredElementList = this.optionsList.filter((el: ProjectCustomStatuses | Floor) => el.name.toLowerCase().includes(event.target.value.toLowerCase()))
  }

  addColorToSelectValue(statusSelected: ProjectCustomStatuses): any {
    if (statusSelected.orderNumber < 0) {
      if (statusSelected.orderNumber !== -3) return 'white'
      else return 'black'
    }
    if (statusSelected.color === ProjectCustomColors.YELLOW || statusSelected.color === ProjectCustomColors.GREY || statusSelected.color === ProjectCustomColors.MINT) {
      return "black"
    } else return "white"
  }

  clearField() {
    this.selectedOption = null
    this.inputValue = null;
    this.onChange(null);
    this.changeEl.emit(null);
  }

  toggleDropDownMenu(materialSelect: HTMLElement) {
    if(materialSelect.hasAttribute('openMenu')) {
      materialSelect.removeAttribute('openMenu')
      this.closeSelect()
    } else materialSelect.setAttribute('openMenu', 'true')
  }


  closeSelect() {
    this.inputRef.nativeElement.value = this.inputValue ? this.inputValue : null
    if(this.filterListTimeout) clearTimeout(this.filterListTimeout)
    this.filterListTimeout = setTimeout(() => this.filteredElementList = [...this.optionsList], 250)
    this.close.emit()
  }

  protected readonly originalOrder = originalOrder;
}
