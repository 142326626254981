<div class="sidebar-tablet-mock"></div>
<div class="sidebar-wrapper" #sidebarWrapper>
  <span class="sidebar-menuIcon-wrapper">
    <svg class="logo-sidebar">
      <use spriteVersion iconName="colorjob-logo-sidebar"></use>
    </svg>
    <svg class="tablet-menu" (click)="sidebarWrapper.setAttribute('isOpenMenu', 'true')">
      <use spriteVersion iconName="tablet-menu"></use>
    </svg>
    <svg class="close-sidebar" (click)="sidebarWrapper.removeAttribute('isOpenMenu')">
      <use spriteVersion iconName="close-sidebar"></use>
    </svg>
  </span>
  <nav class="sidebar-nav" >
      <ul class="nav-list" *ngIf="showSuperAdminMainMenu">
        <li class="nav-list__item">
          <a class="nav-list__link" routerLinkActive="nav-list__link--active" id="linkDashboard" routerLink="dashboard" (click)="sidebarWrapper.removeAttribute('isOpenMenu')">
            <svg class="nav-list__icon">
              <use spriteVersion iconName="dashboard"></use>
            </svg>
            <span>Dashboard</span>
          </a>
        </li>
        <li class="nav-list__item">
          <a class="nav-list__link" id="linkCompanies" routerLinkActive="nav-list__link--active" routerLink="companies" (click)="sidebarWrapper.removeAttribute('isOpenMenu')">
            <svg class="nav-list__icon">
              <use spriteVersion iconName="companies"></use>
            </svg>
            <span>Companies</span>
          </a>
        </li>
        <li class="nav-list__item">
          <a class="nav-list__link" id="linkProjects" routerLinkActive="nav-list__link--active" routerLink="projects" (click)="sidebarWrapper.removeAttribute('isOpenMenu')">
            <svg class="nav-list__icon">
              <use spriteVersion iconName="projects"></use>
            </svg>
            <span>Projects</span>
          </a>
        </li>
        <li class="nav-list__item">
          <a class="nav-list__link" id="linkUsers"  routerLinkActive="nav-list__link--active" routerLink="users" (click)="sidebarWrapper.removeAttribute('isOpenMenu')">
            <svg class="nav-list__icon">
              <use spriteVersion iconName="users"></use>
            </svg>
            <span>Users</span>
          </a>
        </li>
      </ul>


    <ng-container *ngIf="!showSuperAdminMainMenu">
      <div class="back-link-wrapper">
        <a class="back-link" routerLink="companies"  id="linkBackAllCompanies"  *ngIf="userRole === UserRole.ROLE_SUPERADMIN " (click)="sidebarWrapper.removeAttribute('isOpenMenu')">
          <svg class="nav-list__icon">
            <use spriteVersion iconName="arrow-back"></use>
          </svg>
          <span>Back To All Companies</span>
        </a>
        <a class="nav-list__link" id="linkDashboardAdminOrEmployee" routerLinkActive="nav-list__link--active" [routerLink]="['company', company?.id, 'dashboard']" *ngIf="userRole !== UserRole.ROLE_SUPERADMIN " (click)="sidebarWrapper.removeAttribute('isOpenMenu')">
          <svg class="nav-list__icon">
            <use spriteVersion iconName="dashboard"></use>
          </svg>
          <span>Dashboard</span>
        </a>

      </div>

      <div *ngIf="company" class="nav-list-wrapper">
        <p class="company-name company-name-sidebar">{{company.name}}</p>
        <ul class="nav-list nav-list--without-border">
          <li class="nav-list__item" (click)="showProjectsList = !showProjectsList">
            <a class="nav-list__link" id="linkProjectsAdminOrEmployee" routerLinkActive="nav-list__link--active"  [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['company', company.id]" (click)="sidebarWrapper.removeAttribute('isOpenMenu')">
              <svg class="nav-list__icon">
                <use spriteVersion iconName="projects"></use>
              </svg>
              <span>Projects</span>
            </a>
            <ng-scrollbar #ngScrollbar class="project-list-wrapper" [attr.isEmptyProjects]="company.projects.length === 0" [attr.isLotOfProject]="company.projects.length > 6" [autoHeightDisabled]="true">
              <div>
                <ul class="project-list" *ngIf="company && company.projects.length !== 0" [attr.showProjectList]="true">
                  <li class="project-item" *ngFor="let projectEl of company.projects">
                    <a class="project-list-link" title="{{projectEl.name}}" #projectEl id="sidebar-project-link-{{projectEl.id}}"
                       [class.nav-list__link--active]="projectEl.id === project?.id"
                       [routerLink]="['company', company.id, 'projects', projectEl.id, 'builder']" (click)="sidebarWrapper.removeAttribute('isOpenMenu')">{{projectEl.name}}</a>
                  </li>
                </ul>
              </div>
            </ng-scrollbar>
          </li>
          <li class="nav-list__item" *ngIf="userRole !== UserRole.ROLE_EMPLOYEE ">
            <a class="nav-list__link" id="linkUsersInProject" [routerLink]="['company', company?.id, 'users']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="nav-list__link--active" (click)="sidebarWrapper.removeAttribute('isOpenMenu')">
              <svg class="nav-list__icon">
                <use spriteVersion iconName="users"></use>
              </svg>
              <span>Users</span>
            </a>
          </li>
          <li class="nav-list__item" *ngIf="userRole !== UserRole.ROLE_EMPLOYEE && userRole !== UserRole.ROLE_PROJECT_MANAGER">
            <a class="nav-list__link" id="linkCompanySettings" [routerLink]="['company', company?.id, 'settings']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="nav-list__link--active" (click)="sidebarWrapper.removeAttribute('isOpenMenu')">
              <svg class="nav-list__icon">
                <use spriteVersion iconName="settings"></use>
              </svg>
              <span>Settings</span>
            </a>
          </li>
        </ul>
      </div>
    </ng-container>
  </nav>
</div>

<div class="backdrop-sidebar"></div>

