import {Component, OnDestroy, OnInit} from '@angular/core';
import {Page} from "../../../../../_models/page";
import {Activity} from "../../../../../_models/activity";
import {ActivityService} from "../../../../../_services/activity.service";
import {TimePrettifyPipe} from "../../../../../_pipes/timePrettify.pipe";
import {DatePipe} from "@angular/common";
import {Router} from "@angular/router";
import {GlobalStore} from "../../../../../global.store";
import {filter, Subject, Subscription, takeUntil} from "rxjs";
import {originalOrder, waitForElm} from "../../../../../_util/utils";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-activity-project-table',
  templateUrl: './activity-project-table.component.html',
  styleUrls: ['./activity-project-table.component.scss'],
  providers: [TimePrettifyPipe, DatePipe]
})
export class ActivityProjectTableComponent implements OnInit, OnDestroy {
  page = 0;
  pageSize = 10;
  activityPage: Page<Activity> = new Page<Activity>();
  isIOS = this.globalStore.isIOS$;
  isLoadingActivity = false;
  currentTime = Date.now();
  isMobile = this.globalStore.isMobile$;

  companyId: number;
  projectId: number;

  activityMapWithDate: Map<string, Activity[]> = new Map<string, Activity[]>()
  activityDateFilters: string;

  private readonly destroy$ = new Subject<void>()

  constructor(private activitySecrvice: ActivityService,
              private timePrettify: TimePrettifyPipe,
              private date: DatePipe,
              readonly globalStore: GlobalStore,
              public router: Router,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
    this.globalStore.projectId$.pipe(filter((projectId) => !!projectId), takeUntil(this.destroy$)).subscribe((projectId) => {
      this.projectId = projectId;
      this.getActivityList();
    });
  }

  getActivityList(activityDate?: string, isFirstPage?: boolean) {
    this.isLoadingActivity = true
    if(isFirstPage) {
      this.page = 0;
      this.activityMapWithDate = new Map<string, Activity[]>()
    }
    this.activitySecrvice.getActivityList(this.companyId, this.projectId, this.page, this.pageSize, activityDate).subscribe(data => {
      this.activityPage = {
        ...data,
        content: [...this.activityPage.content, ...data.content]
      }
      this.createActivityMapWithDateStrip(data.content)
      this.isLoadingActivity = false;
      waitForElm(`#activity-${data.content[0]?.id}`).then((el) => {
        (el as HTMLElement).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      })
    }, error => this.isLoadingActivity = false)
  }

  setActivityDateFilter(activityDate: string) {
    this.page = 0;
    this.activityDateFilters = activityDate;
    this.activityMapWithDate = new Map<string, Activity[]>()
    this.getActivityList(activityDate)
  }

  sanitizeHtmlText(text: any) {
    document.querySelectorAll('b[areastatus]').forEach((el: Element) => el.classList.add('areaStatusActivity'))
    return this.sanitizer.bypassSecurityTrustHtml(text)
  }

  createActivityMapWithDateStrip(newActivityList: Activity[]) {
    newActivityList.map(activity => {
      let activityDate = this.date.transform(activity.createdAt,'MMMM d, y')
      if(this.activityMapWithDate.has(activityDate)) {
        this.activityMapWithDate.get(activityDate).push(activity)
      } else this.activityMapWithDate.set(activityDate, [activity])
    })
  }


  getTimeCreatedAtActivity(createdAt: number) {
    let diffTime = this.currentTime - createdAt;
    let seconds = Math.floor(diffTime/1000);
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);
    if (days >= 2) return this.date.transform(createdAt, 'MMM d, y h:mm a')
    else return this.timePrettify.transform(createdAt)
  }

  showMoreActivity(){
      this.page += 1;
      if(this.activityPage.totalPages && this.page === this.activityPage.totalPages) return
      this.getActivityList(this.activityDateFilters);
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly originalOrder = originalOrder;
}
