import {Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {filter, Subject, takeUntil} from "rxjs";
import {SwiperOptions} from "swiper";
import {Project} from "../../../../../_models/project";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {GlobalStore} from "../../../../../global.store";
import {UserRole} from "../../../../../_models/user";

@Component({
  selector: 'app-project-details-tabs',
  templateUrl: './project-details-tabs.component.html',
  styleUrls: ['./project-details-tabs.component.scss']
})
export class ProjectDetailsTabsComponent {
  private readonly destroy$ = new Subject<void>();
  selectedTab: string;
  @Input() areaId: number
  @Output() selectedTabEmit: EventEmitter<any> = new EventEmitter<any>()
  @ViewChildren('tabItem') tabItems!: QueryList<ElementRef>;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  config: SwiperOptions = {
    navigation: false,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    slidesPerView: 'auto',
    grabCursor: true,
    freeMode: false,

    breakpoints: {
      375: {
        slidesPerView: 4,
        spaceBetween: 0,
      }
    }
  };

  companyName: string;
  companyId: number;
  project: Project;

  constructor(readonly globalStore: GlobalStore,
              private router: Router ) {

    this.updateSelectedTab(this.router.url)
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.updateSelectedTab(this.router.url)
      });

    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
    this.globalStore.project$.pipe(takeUntil(this.destroy$)).subscribe((project) => this.project = project);
  }


  private updateSelectedTab(url: string): void {
    this.selectedTab = url.split('?')[0].split('/').pop();
    this.getSubTitle(this.selectedTab);
  }

  setSubtitle(selectedTab: string) {
    this.selectedTab = selectedTab;
    setTimeout(() => {
      this.selectedTabEmit.emit(this.selectedTab)
    })
  }

  getSubTitle(subtitle: string) {
    switch (subtitle) {
      case 'builder': {
        this.setSubtitle('Builder')
        break
      }
      case 'progress': {
        this.setSubtitle('Project Progress')
        break
      }
      case 'areas': {
        this.setSubtitle('Areas')
        break
      }
      case 'tasks': {
        this.setSubtitle('Tasks')
        break
      }
      case 'users': {
        this.setSubtitle('Users')
        break
      }
      case 'activity': {
        this.setSubtitle('Activity')
        break
      }
      case 'documents': {
        this.setSubtitle('Documents')
        break
      }
      case 'drawings': {
        this.setSubtitle('Drawings')
        break
      }
      case 'materials': {
        this.setSubtitle('Materials')
        break
      }
      case 'settings': {
        this.setSubtitle('Settings')
        break
      }
    }
  }

  scrollToTab(id: number) {
    if(window.innerWidth < 1540){
      const tabItemElement = this.tabItems.find((_, idx) => idx === id)?.nativeElement;
      if (tabItemElement && this.scrollContainer) {
        const scrollContainerElement = this.scrollContainer.nativeElement;
        const itemPosition = tabItemElement.offsetLeft;
        const containerWidth = scrollContainerElement.clientWidth;
        const itemWidth = tabItemElement.clientWidth;

        // Calculate the scroll position to center the clicked item in the view
        const scrollToPosition = itemPosition - (containerWidth / 2) + (itemWidth / 2);

        scrollContainerElement.scrollTo({
          left: scrollToPosition,
          behavior: 'smooth'
        });
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly UserRole = UserRole;
}
