<div class="ng-select-wrapper">
  <div class="options-btn-wrapper" #optionsSelect
       [enabled]="optionsSelect.hasAttribute('openMenu')"
       (appOutsideClick)="optionsSelect.removeAttribute('openMenu'); closeSelect();">
    <button class="options-btn"
            id="optionsSelectBtn"
            type="button"
            (click)="optionsSelect.toggleAttribute('openMenu')"
            [attr.value]="optionsFromKeysList.indexOf(optionsMap.get(selectedOption))">
      <span>{{optionsMap?.get(selectedOption)}}</span>
      <svg class="options-icon-dropdown">
        <use spriteVersion iconName="arrow-select-area-status"></use>
      </svg>
    </button>


    <div class="options-menu-wrapper">
      <ul class="options-menu">
        <li class="options-menu__item" *ngFor="let option of optionsFromKeysList; let idx = index" (click)="changeEl.emit(idx+1); optionsSelect.removeAttribute('openMenu')">
          <p class="options-menu__text"
             [attr.selectedOption]="idx === optionsFromKeysList.indexOf(optionsMap.get(selectedOption))">
            {{option}}
          </p>
        </li>
      </ul>
    </div>
  </div>

</div>

