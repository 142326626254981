import {
  Component,
  ElementRef,
  EventEmitter,
  Input, OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {AreasService} from "../../../../../../../_services/areas.service";
import {AreaComment} from "../../../../../../../_models/areaComment";
import {User, UserRole} from "../../../../../../../_models/user";
import {NotificationService} from "../../../../../../../_services/notification.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {SelectedArea} from "../../../types";
import {GlobalStore} from "../../../../../../../global.store";
import {Subject, take, takeUntil} from "rxjs";
import {Router} from "@angular/router";

const fadeIn = trigger('fadeIn', [
  state('void', style({ opacity: 0 })),
  transition(':enter, :leave', [animate(300)]),
]);

@Component({
  selector: 'app-activity-area',
  templateUrl: './activity-area.component.html',
  styleUrls: ['./activity-area.component.scss'],
  animations: [fadeIn]
})
export class ActivityAreaComponent implements OnDestroy {
  @ViewChild('fileInput') fileInputRef: ElementRef;
  currentUser: User;
  imageFormatsToRenderImage: string[] = ['jpeg', 'png', 'svg', 'webp', 'jpg'];
  private _selectedArea: SelectedArea;
  private _comments: AreaComment[] = [];
  @Input()
  set selectedArea(value: SelectedArea) {
    if (value) {
      this._selectedArea = value;
    }
  }
  get selectedArea () {
    return this._selectedArea;
  }

  @Input()
  set comments(value: AreaComment[]) {
    this._comments = value
  };

  get comments() {
    return this._comments;
  }

  @Output() updated: EventEmitter<void> = new EventEmitter();
  @Output() addComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteCommentEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() addAttachments: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeAttachments: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileToPreview: EventEmitter<any> = new EventEmitter<any>()

  companyId: number;
  projectId: number;

  private readonly destroy$ = new Subject<void>()

  constructor(private areasService: AreasService,
              readonly globalStore: GlobalStore,
              public notifService: NotificationService,
              private router: Router) {
    this.globalStore.currentUser$.pipe(take(1)).subscribe((user) => this.currentUser = user);
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe((projectId) => this.projectId = projectId);
  }

  commentClicked(event: any) {
    let mentionItem = event.target.parentElement;
    if (mentionItem.classList.contains('mention') && mentionItem.getAttribute("no-link") !== 'true') {
      if (this.currentUser.role === UserRole.ROLE_EMPLOYEE) this.router.navigateByUrl(`/system/company/${this.companyId}/profile`);
      else this.router.navigateByUrl(`/system/company/${this.companyId}/users/${mentionItem.getAttribute('data-id')}`);
    }
  }

  isFileCanBePreviewed(fileName: string) {
    let result = false;
    this.imageFormatsToRenderImage.forEach(e => {
      if (fileName.endsWith(e)) {
        result = true;
        return;
      }
    })
    return result;
  }

  createComment(comment: AreaComment) {
    this.addComment.emit(comment)
    this.selectedArea.area.commentsAmount = this.selectedArea.area.comments.length + 1;
    this.selectedArea.area.attachmentsAmount = this.selectedArea.area.files.length
    this.updated.emit();
  }

  editComment(comment: AreaComment) {
    this.updateComment.emit(comment)
    this.selectedArea.area.attachmentsAmount = this.selectedArea.area.files.length
    this.updated.emit();
  }

  deleteComment(comment: AreaComment) {
    this.areasService.deleteAreaComment(comment.id).subscribe( () => {
      this.notifService.successNotification('Changes have been saved')
      this.deleteCommentEmitter.emit(comment.id)
      this.selectedArea.area.commentsAmount = this.selectedArea.area.comments.length - 1;
      this.selectedArea.area.attachmentsAmount = this.selectedArea.area.files.length
      this.updated.emit();
    })
  }

  updateAttachments(filesAmount: number) {
    this.selectedArea.area.attachmentsAmount = filesAmount
    this.selectedArea.area.commentsAmount = this.selectedArea.area.comments.length
    this.updated.emit();
  }

  scrollTo(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center'});
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly UserRole = UserRole;
}
