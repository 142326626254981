<div class="area-details-wrapper">
  <app-area-details-header [selectedArea]="selectedArea"></app-area-details-header>
  <div class="area-details-select-with-progress-wrapper">
    <div class="area-status-with-weight-wrapper">
      <div class="ng-select-wrapper areastatusSelect">
        <p class="status-progress-label status-progress-label--margin-bottom">Status:</p>
        <div class="area-status-btn-wrapper" #areaStatusSelect [enabled]="areaStatusSelect.hasAttribute('openMenu')" (appOutsideClick)="areaStatusSelect.removeAttribute('openMenu')">
          <button class="area__status-btn" [attr.istodo]="selectedArea?.area.status.orderNumber === -3" [style.background-color]="addBackgroundToStatusItem(selectedArea.area.status)" [style.color]="addColorToSelectValue(selectedArea.area.status)" (click)="isLoadingStatus ? null : areaStatusSelect.toggleAttribute('openMenu')" >
            <span>{{selectedArea.area.status.name}}</span>
            <svg *ngIf="!isLoadingStatus" class="area__status-icon-dropdown" [style.stroke]="addColorToSelectValue(selectedArea.area.status)">
              <use spriteVersion iconName="arrow-select-area-status"></use>
            </svg>

            <div *ngIf="isLoadingStatus" class="loader-spin">
              <svg [style.stroke]="addColorToSelectValue(selectedArea.area.status)">
                <use spriteVersion iconName="loader-icon"></use>
              </svg>
            </div>
          </button>
          <div class="area__status-menu-wrapper">
            <ul class="area__status-menu">
              <li class="area__status-menu__item" *ngFor="let areaStatus of projectStatuses" (click)="changeAreaStatus(areaStatus.id); areaStatusSelect.removeAttribute('openMenu')">
                <p class="area__status-menu__text" [attr.istodo]="areaStatus.orderNumber === -3" [style.color]="addColorToSelectValue(areaStatus)" [style.background-color]="addBackgroundToStatusItem(areaStatus)" [attr.selectedStatus]="areaStatus.name === selectedArea.area.status.name">{{areaStatus.name}}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <app-area-weight-selector [selectedArea]="selectedArea"
                                [isLoadingWeight]="isLoadingWeight"
                                [isAreaDetailsModal]="true"
                                (changeAreaWeight)="changeAreaWeight($event)">
      </app-area-weight-selector>
    </div>

    <div class="area-details-progress" *ngIf="selectedArea.area.status.orderNumber === AreaStatusDefaultOrder.IN_PROGRESS">
      <p class="status-progress-label">Status Progress <span *ngIf="!isAreaDetailsPage" class="status-progress-percent">{{statusProgressDump}}%</span></p>

      <div class="area-details-progress-with-btns">
        <div class="area-deteils-progress-with-percent-for-area-page">
          <div class="area-status-progress-list-wrapper">
            <button type="button" class="change-status-progress-btn" [disabled]="statusProgressDump === 0" (click)="$event.stopPropagation(); startChangeStatusProgress = true; statusProgressDump = statusProgressDump - 10">
              <svg class="status-progress-btn-icon">
                <use spriteVersion iconName="minus"></use>
              </svg>
            </button>
            <ul class="area-status-progress-list">
              <li class="area-status-progress-list__item" *ngFor="let bar of [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"  #progressSquare [attr.ignored]="calculateProgressSquareAttrs(progressSquare, bar)" (click)="startChangeStatusProgress = true; statusProgressDump = bar"></li>
            </ul>
            <button type="button" class="change-status-progress-btn" [disabled]="statusProgressDump === 100" (click)="$event.stopPropagation(); startChangeStatusProgress = true; statusProgressDump = statusProgressDump + 10">
              <svg class="status-progress-btn-icon">
                <use spriteVersion iconName="black-plus"></use>
              </svg>
            </button>
          </div>
          <span *ngIf="isAreaDetailsPage" class="status-progress-percent">{{statusProgressDump}}%</span>
        </div>
        <ul class="btns-list" *ngIf="startChangeStatusProgress">
          <li>
            <button type="button" [ngClass]="isAreaDetailsPage ? 'create-item-btn' : 'btn-confirm'" (click)="isLoadingStatusProgress ? null : updateAreaStatusProgress()">
              <ng-template [ngIf]="!isAreaDetailsPage">
                <svg *ngIf="!isLoadingStatusProgress" class="btn-confirm__check-icon">
                  <use spriteVersion iconName="check"></use>
                </svg>

                <div *ngIf="isLoadingStatusProgress" class="loader-spin loader-spin-progress">
                  <svg [style.stroke]="'black'">
                    <use spriteVersion iconName="loader-icon"></use>
                  </svg>
                </div>
                <img class="check-icon-for-ios" src="assets/images/check-icon-png.png" alt="check-icon">
              </ng-template>
              <span *ngIf="isAreaDetailsPage">Save</span>
            </button>
          </li>
          <li>
            <button type="button" class="btn-confirm" (click)="statusProgressDump = selectedArea.area.statusProgress; startChangeStatusProgress = false">
              <svg class="btn-confirm__cansel-icon">
                <use spriteVersion iconName="close-icon"></use>
              </svg>
              <img class="cross-icon-for-ios" src="assets/images/cross-icon-png.png" alt="cross-icon">
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
