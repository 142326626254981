<app-activity-date-filters (changeDate)="setActivityDateFilter($event)" (clearDate)="activityDateFilters = null; getActivityList(null, true)"></app-activity-date-filters>

<div class="activity-table-with-btn-wrapper">
  <ng-scrollbar [attr.isIOS]="(isIOS | async)" [attr.pagination-showed]="page !== activityPage.totalPages" [autoHeightDisabled]="true" [track]="'vertical'" class="table-wrapper-with-dots">
    <div class="innerScrollBarWrapper">
      <div class="table-wrapper-with-scroll">
        <div *ngIf="!activityPage.empty; else emptyList">
          <div *ngFor="let activityWithDate of activityMapWithDate | keyvalue: originalOrder ">
            <p class="date-dividing-strip">
              <span class="date-dividing-strip--text">{{ activityWithDate.key }}</span>
            </p>
            <ul class="table-activity" *ngIf="activityWithDate.value.length > 0">
              <li class="activity-item" [id]="'activity-' + activity.id" *ngFor="let activity of activityWithDate.value">
                <div class="activity-info-without-createAt">
                  <p class="activity-id-wrapper" *ngIf="!(isMobile | async)">
                    <span class="activity-id" [attr.isUserHasAccess]="activity.userHasAccess" (click)="activity.userHasAccess ? router.navigateByUrl('/system' + activity.relatedToLink) : ''">
                      <span>{{activity.relatedToId}}</span>
                    </span>
                  </p>
                  <span class="activity-text-wrapper">
                    <ng-template [nestedEllipsis]>
                      <span *ngIf="(isMobile | async)" class="activity-id" (click)="activity.userHasAccess ? router.navigateByUrl('/system' + activity.relatedToLink) : ''">{{activity.relatedToId}}</span>
                      <span *ngIf="(isMobile | async)" class="activity-text" [innerHTML]="sanitizeHtmlText(activity.text)"></span>
                    </ng-template>
                    <ng-template [nestedEllipsis]>
                      <span *ngIf="!(isMobile | async)" class="activity-text" [innerHTML]="sanitizeHtmlText(activity.text)"></span>
                    </ng-template>
                  </span>
                </div>
                <p class="activity-createdAt">{{getTimeCreatedAtActivity(activity.createdAt)}}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <app-loader *ngIf="isLoadingActivity"></app-loader>
    </div>
  </ng-scrollbar>

  <button class="show-more-btn" *ngIf="activityPage.content.length > 0 && page !== activityPage.totalPages - 1 && !activityPage.empty" loader="black" [disabled]="isLoadingActivity" [showLoader]="activityPage && isLoadingActivity" (click)="showMoreActivity()">Show More</button>

  <ng-template #emptyList>
  <div class="empty-list-wrapper">
    <p class="empty-list-text" *ngIf="activityPage.empty; else notFound">The list of activities is empty.</p>
    <ng-template #notFound>
      <p class="empty-list-text">No Activities found</p>
    </ng-template>
    <img class="empty-list-icon" src="assets/images/empty-list.png" alt="empty-list">
  </div>
  </ng-template>
</div>

