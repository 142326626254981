import {
  Component, EventEmitter, Input, OnDestroy, Output,
} from '@angular/core';
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {Floor} from "../../../../../../_models/floor";
import {FloorsService} from "../../../../../../_services/floors.service";
import {NotificationService} from "../../../../../../_services/notification.service";
import {minLengthValidator, requiredValidator} from "../../../../../../_util/validators";
import {FormBuilder, FormGroup} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import {User, UserRole} from "../../../../../../_models/user";
import {ProjectViewStore} from "../project-view.store";
import {of, Subject, Subscription, take, takeUntil} from "rxjs";
import {GlobalStore} from "../../../../../../global.store";
import {waitForElm} from "../../../../../../_util/utils";
import {modalAnimation} from "../../../../../../config/constants";

@Component({
  selector: 'app-floors-swiper',
  templateUrl: './floors-swiper.component.html',
  styleUrls: ['./floors-swiper.component.scss'],
  animations: [modalAnimation]
})
export class FloorsSwiperComponent implements OnDestroy {
  @Input() roomsAmount: number;
  isOpenAreaWeightSettings: boolean = false;
  floorsShortList: Floor[] = [];
  selectedFloor: Floor;
  currentFloor: Floor;
  currentUser: User;
  isMobile = this.globalStore.isMobile$;
  isCdkDragDisabled = false;

  deletingPopupOpened = false;
  deletingBtnDisabled = false;


  createFloorForm: FormGroup = this.fb.group({
    name: [null, [minLengthValidator('FLOOR_UNIT_NAME'), requiredValidator()]]
  })

  openCustomizeStatusesForm = false;

  private readonly destroy$ = new Subject<void>()

  isAddFloorMenuOpened = false;
  isAddFloorFormOpened = false;

  constructor(private floorsService: FloorsService,
              private fb: FormBuilder,
              readonly globalStore: GlobalStore,
              readonly projectViewStore: ProjectViewStore,
              private notifService: NotificationService) {
    this.globalStore.currentUser$.pipe(take(1)).subscribe((user: User) => this.currentUser = user);
    this.projectViewStore.isCdkDragDisabled$.subscribe((data) => this.isCdkDragDisabled = data);
    this.projectViewStore.floorsShortList$.subscribe((data) => this.floorsShortList = data);
    this.projectViewStore.currentFloor$.subscribe((data) => this.currentFloor = data);

    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe(projectId => {
      this.projectViewStore.updateCurrentFloor(null);
      if (projectId === null) return;
      this.projectViewStore.loadFloorsShortList(of({goToFirst: true}));
    })
  }

  scrollToFloor(floorItemId: number) {
    waitForElm(`#floor-${floorItemId}`).then((el) => (el as HTMLElement).scrollIntoView({behavior: 'smooth'}));
  }

  createNewFloor(name: string | null, duplicateFloorId: number | null) {
    if(name === '') return
    this.floorsService.createFloor(this.projectViewStore.projectId, name, duplicateFloorId).subscribe((data) => {
      this.projectViewStore.addFloor(data);
      this.projectViewStore.updateCurrentFloor(data);
      this.projectViewStore.updateIsCdkDragDisabled(false);
      this.projectViewStore.loadFloorDuplicateMenuList();
      this.globalStore.loadProjectProgress();
      this.createFloorForm.reset();
      this.closeAddFloorForm()
      this.scrollToFloor(data.id)
      this.notifService.successNotification('Changes have been saved');
    }, (error: HttpErrorResponse) => {
      if (error.status === 400 && error.error.error === 'Name already in use') this.createFloorForm.controls['name'].setErrors({error: 'Floor with such name already exists'})
    })
  }

  updateAreasWeight() {
    this.projectViewStore.loadFloorsShortList(of({goToFirst: true}))
  }

  closeAddFloorForm() {
    this.isAddFloorMenuOpened = false;
    this.isAddFloorFormOpened = false;
    this.createFloorForm.reset();
    this.projectViewStore.updateIsCdkDragDisabled(false)
  }

  dropFloor(event: CdkDragDrop<Floor[]>) {
    let newOrder = event.currentIndex;
    let oldOrder = event.previousIndex;

    if (newOrder === oldOrder) return;
    this.projectViewStore.changeFloorOrder(of({newOrder, oldOrder}));
    this.floorsService.updateFloorOrderById(newOrder, event.item.data.id).subscribe();
  }

  deleteFloor() {
    this.deletingBtnDisabled = true;

    this.floorsService.deleteFloorById(this.selectedFloor.id).subscribe(() => {
      this.projectViewStore.deleteFloor(this.selectedFloor);
      this.globalStore.loadProjectProgress();
      this.goToFloor(this.floorsShortList[0].id);
      this.deletingPopupOpened = false;
      this.notifService.successNotification('Changes have been saved');
      this.deletingBtnDisabled = false;
    }, error => this.deletingBtnDisabled = false)
  }

  createFloor() {
    if (this.createFloorForm.invalid) return;
    this.createNewFloor(this.createFloorForm.controls['name'].value, null)
  }


  goToFloor(floorId: number) {
    if (this.currentFloor.id === floorId) return;
    this.currentFloor.id = floorId
    this.projectViewStore.loadFloor(of({floorId}));
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly UserRole = UserRole;
    protected readonly Floor = Floor;
  protected readonly of = of;
}
