<div class="backdrop" *ngIf="!selectedFileForPreview" [@modalAnimation]>
  <div [enabled]="true" (appOutsideClick)="startChangeStatusProgress = false" class="modal modal--wider">
    <button type="button" id="btnCloseModal" class="close-modal-btn" (click)="close.emit(); removeMentionList(); startChangeStatusProgress = false">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>

    <app-area-details-section [selectedArea]="selectedArea"
                              [projectStatuses]="projectStatuses"
                              (updated)="updated.emit($event)" (updateHistory)="updateHistory()">
    </app-area-details-section>

    <!--Area Details Tabs-->

    <ul class="area-details-tabs-list">
      <li class="area-details-tabs-list__item">
        <a class="area-details-tabs-list__link" [class.active]="activeTab === 'activity'" (click)="activeTab = 'activity'">Activity</a>
      </li>
      <li class="area-details-tabs-list__item">
        <a class="area-details-tabs-list__link" [class.active]="activeTab === 'history'" (click)="activeTab = 'history'">History</a>
      </li>
      <li class="area-details-tabs-list__item">
        <a class="area-details-tabs-list__link" [class.active]="activeTab === 'files'" (click)="activeTab = 'files'">Files</a>
      </li>
    </ul>
    <ng-container *ngIf="selectedArea">
      <app-activity-area *ngIf="activeTab === 'activity'" [attr.display]="activeTab === 'activity' ? 'true' : 'false'"
                         [selectedArea]="selectedArea" [comments]="areaFormStore.comments$ | async"
                         (updated)="updated.emit(selectedArea); updateHistory();"
                         (fileToPreview)="selectedFileForPreview = $event" (addComment)="createComment($event)"
                         (updateComment)="editComment($event)" (deleteCommentEmitter)="deleteComment($event)">
      </app-activity-area>
      <app-history-area *ngIf="activeTab === 'history'" [attr.display]="activeTab === 'history' ? 'true' : 'false'" [selectedArea]="selectedArea" [history]="history"></app-history-area>
      <app-files-area *ngIf="activeTab === 'files'" [attr.display]="activeTab === 'files' ? 'true' : 'false'" [selectedArea]="selectedArea"
                      [files]="selectedArea.area.files" (removeAreaFile)="removeAreaFile.emit($event)" (fileToPreview)="selectedFileForPreview = $event"></app-files-area>
    </ng-container>
  </div>
</div>

<app-file-preview *ngIf="selectedFileForPreview"
                  [file]="selectedFileForPreview"
                  (close)="selectedFileForPreview = null"
                  [@modalAnimation]>
</app-file-preview>
