import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {User, UserRole} from "../../../../_models/user";
import {Router} from "@angular/router";
import {GlobalStore} from "../../../../global.store";
import {take} from "rxjs";

@Component({
  selector: 'app-filter-fields',
  templateUrl: './filter-fields.component.html',
  styleUrls: ['./filter-fields.component.scss']
})
export class FilterFieldsComponent implements AfterViewInit{
  @ViewChild('filtersWrapper') filtersWrapper: ElementRef;
  @Input() btnText: string = '';
  @Input() hideAllBtns: boolean = false;
  @Input() hideButtonText = '';
  @Input() showButtonText = '';
  @Input() disabled: boolean;
  @Input() filtersAmount: number = 0;
  @Input() selectedItemsAmount: number = 0;
  @Input() set searchPlaceholder(value: string) {
    if(window.innerWidth <= 650 && !this.isProjectsPage) {
      this._searchPlaceholder = value.slice(0, 22) + '...'
      return
    }

    if(this.isProjectsPage &&  window.innerWidth <= 450) {
      this._searchPlaceholder = value.slice(0, 22) + '...'
      return
    }

    if(this.isProjectsPage && window.innerWidth >= 451 &&  window.innerWidth <= 800) {
      this._searchPlaceholder = value.slice(0, 30) + '...'
      return
    }
    if(this.isUsersPage &&  window.innerWidth <= 800) {
      this._searchPlaceholder = value.slice(0, 18) + '...'
    }
    else this._searchPlaceholder = value;
  }

  get searchPlaceholder() {
    return this._searchPlaceholder
  }

  @Output() inputChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteSelectedItem: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancelSelectedItem: EventEmitter<string> = new EventEmitter<string>();
  @Output() openFormBtnClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() openAddUsersBtnClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() clearAllFilters: EventEmitter<void> = new EventEmitter<void>();
  @Output() hideCompleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() export: EventEmitter<void> = new EventEmitter<void>();
  _searchPlaceholder: string;
  isOpenFilters: boolean = false;
  input: string = '';
  isOnBlur: boolean = false
  currentUser: User;
  isUsersInProjectPage: any;
  isHide = false;
  isWiderInput = false;
  isTasksPage = false;
  isMaterialsPage = false;
  isUsersPage = false;
  isCompaniesPage = false;
  isProjectsPage = false;
  isDocumentsOrDrawingsPage = false;
  isReplaceSearchAndHideBtn = false;
  isMobile = this.globalStore.isMobile$;
  isShorterBtnText = false;

  constructor(private route: Router,
              readonly globalStore: GlobalStore) {
    this.globalStore.currentUser$.pipe(take(1)).subscribe((user) => this.currentUser = user);
    this.isUsersInProjectPage = this.route.routerState.snapshot.url.includes('users') && this.route.routerState.snapshot.url.includes('projects')
    this.isTasksPage = this.route.routerState.snapshot.url.includes('tasks')
    this.isCompaniesPage = this.route.routerState.snapshot.url.includes('companies')
    this.isMaterialsPage = this.route.routerState.snapshot.url.includes('materials')
    this.isDocumentsOrDrawingsPage = this.route.routerState.snapshot.url.includes('documents') || this.route.routerState.snapshot.url.includes('drawings')
    this.isUsersPage = this.route.routerState.snapshot.url.includes('users');
    this.isProjectsPage = this.route.routerState.snapshot.url.endsWith('projects') || /company\/\d+$/.test(this.route.routerState.snapshot.url);
    this.isReplaceSearchAndHideBtn =  this.isTasksPage || this.route.routerState.snapshot.url.includes('projects') || this.route.routerState.snapshot.url.includes('company')
    this.isWiderInput = (this.route.routerState.snapshot.url.includes('projects') && !this.route.routerState.snapshot.url.includes('company')) || this.route.routerState.snapshot.url.includes('tasks');
  }

  ngAfterViewInit() {
    if(window.innerWidth <= 830) this.isShorterBtnText = true
  }

  onBlurInput() {
    this.isOnBlur = true;
  }

  clearInput() {
    this.input = '';
    this.inputChanged.emit(undefined);
  }

  deleteSelected(event: any) {
    this.deleteSelectedItem.emit(event)
  }

  cancelSelected(event: any) {
    this.cancelSelectedItem.emit(event)
  }

  onFilters(event:any) {
    const targetElement = event.target
    if ((targetElement.tagName === 'svg' && targetElement.classList.contains('close-icon-forCheck')) || targetElement.tagName === 'use') {
      if(this.isOpenFilters) {
        this.isOpenFilters = !this.isOpenFilters;
        return
      } else return
    }
    this.isOpenFilters = !this.isOpenFilters;
  }

    protected readonly UserRole = UserRole;
  protected readonly window = window;
}
