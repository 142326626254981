import {Injectable, OnDestroy} from "@angular/core";
import {AreaComment} from "../../../../../../_models/areaComment";
import {RoomAreaHistoryItem} from "../../../../../../_models/roomAreaHistoryItem";
import {ComponentStore} from "@ngrx/component-store";
import {Observable, Subject, Subscription, switchMap, takeUntil, tap} from "rxjs";
import {AreasService} from "../../../../../../_services/areas.service";
import {DomSanitizer} from "@angular/platform-browser";
import {GlobalStore} from "../../../../../../global.store";
import {FormFile} from "../../../../../../_models/material";

export interface AreaFormState {
  comments: AreaComment[] | null;
  history: RoomAreaHistoryItem[] | null;
}

@Injectable()
export class AreaFormStore extends ComponentStore<AreaFormState> implements OnDestroy {
  companyId: number;
  projectId: number;

  private readonly destroySubj$ = new Subject<void>()

  constructor(private areasService: AreasService,
              readonly globalStore: GlobalStore,
              private domSanitizer: DomSanitizer) {
    super({
      comments: null,
      history: null,
    });

    this.globalStore.companyId$.pipe(takeUntil(this.destroySubj$)).subscribe((companyId) => this.companyId = companyId);
    this.globalStore.projectId$.pipe(takeUntil(this.destroySubj$)).subscribe((projectId) => this.projectId = projectId);
  }

  readonly comments$ = this.select(
    (state) => state.comments ?? []
  );

  readonly commentsWithFiles$ = this.select(
    (state) => {
      return state.comments?.filter(e => e.files.length > 0) ?? []
    }
  );


  readonly history$ = this.select(
    (state) => state.history ?? []
  );

  readonly loadComments = this.effect((trigger$: Observable<{ areaId: number }>) => trigger$.pipe(
    switchMap(({areaId}) =>
      this.areasService.getAreaComments(areaId).pipe(
        tap((comments) => {
          comments.forEach(e => e.safeHtml = this.domSanitizer.bypassSecurityTrustHtml(e.html))
          this.patchState({comments: comments});
        }))
    )
  ))


  readonly addComment = this.updater((state: AreaFormState, comment: AreaComment) => {
    const updatedComments = state.comments;
    comment.safeHtml = this.domSanitizer.bypassSecurityTrustHtml(comment.html);
    updatedComments.unshift(comment);

    return {
      ...state,
      comments: updatedComments
    };
  });

  readonly updateComment = this.updater((state: AreaFormState, updatedComment: AreaComment) => {
    updatedComment.safeHtml = this.domSanitizer.bypassSecurityTrustHtml(updatedComment.html);

    const updatedComments = state.comments.map(comment => {
      if (comment.id === updatedComment.id) return updatedComment;
      return comment;
    });

    return {
      ...state,
      comments: updatedComments
    };
  });

  readonly deleteComment = this.updater((state: AreaFormState, commentId: number) => {
    const updatedComments = state.comments.filter(comment => comment.id !== commentId);

    return {
      ...state,
      comments: updatedComments
    };
  });

  readonly deleteAllFilesFromComment = this.updater(
    (state: AreaFormState, commentId: number) => {
      const updatedComments = state.comments.map(comment => {
        if (comment.id !== commentId) {
          return comment;
        }

        return {
          ...comment,
          files: [],
        };
      });

      return {
        ...state,
        comments: updatedComments,
      };
    }
  );

  readonly loadHistory = this.effect((trigger$: Observable<{ areaId: number }>) => trigger$.pipe(
    switchMap(({areaId}) =>
      this.areasService.getAreaHistory(areaId).pipe(
        tap((history) => {
          this.patchState({history: history});
        }))
    )
  ))

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.destroySubj$.next()
    this.destroySubj$.complete()
  }
}
