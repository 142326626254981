import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {modalAnimation} from "../../../../../../config/constants";
import {Room} from "../../../../../../_models/room";
import {NotificationService} from "../../../../../../_services/notification.service";
import {GlobalStore} from "../../../../../../global.store";
import {UserRole} from "../../../../../../_models/user";
import {AreaWeightSettingsService} from "../../../../../../_services/area-weight-settings.service";
import {tap} from "rxjs";
import {AreaWeight} from "../../../../../../_models/area";
import {NgScrollbar} from "ngx-scrollbar";

@Component({
  selector: 'app-area-weight-settings-form',
  templateUrl: './area-weight-settings-form.component.html',
  styleUrls: ['./area-weight-settings-form.component.scss'],
  animations: [modalAnimation]
})
export class AreaWeightSettingsFormComponent {
  @ViewChild('areasListWrapperRef') areasListWrapperRef: NgScrollbar;
  @ViewChild('areasListRef') areasListRefAsElement: ElementRef;
  @Output() created:EventEmitter<any> = new EventEmitter<any>();
  @Output() updated:EventEmitter<any> = new EventEmitter<any>();
  @Output() close:EventEmitter<any> = new EventEmitter<any>();

  roomShortList: Room[] = [];
  isOpenConfirmModal = false;
  isResetBtnDisabled = true;

  submitBtnDisabled = false;

  projectId: number;
  selectedRoomName: string;
  areasList: string[] = [];
  areasWithWeightsMap: Map<string, AreaWeight> = new Map<string, AreaWeight>()
  constructor(
              private areaWeightSettingsService: AreaWeightSettingsService,
              private notifService: NotificationService,
              readonly globalStore: GlobalStore) {

    this.globalStore.projectId$.pipe(tap(projectId => this.projectId = projectId)).subscribe( ()=> {
      this.getRoomsShortList(this.projectId)
    })
  }

  getRoomsShortList(projectId: number) {
    this.areaWeightSettingsService.getRooms(projectId).subscribe(data => {
      this.roomShortList = data
    })
  }
  getAreasByRoomName(roomName: string) {
    this.areaWeightSettingsService.getAreasByRoomName(this.projectId, this.selectedRoomName).subscribe(data => {
      this.areasList = data
      this.areasWithWeightsMap = new Map<string, AreaWeight>()
      this.setAllAreaWeightsToMedium(this.areasList)
      setTimeout(() => {
        this.areasListWrapperRef.update()
      },100)
    })
  }

  setAllAreaWeightsToMedium(areasList: string[]) {
    for(let i = 0; i < this.areasList.length; i++){
      this.areasWithWeightsMap.set(this.areasList[i], AreaWeight.MEDIUM)
    }
    this.isResetBtnDisabled = true
  }

  checkIfAnyAreasWeightHasChanges() {
    let isAllWeightMedium = true;
    this.areasWithWeightsMap.forEach((value: AreaWeight, key) => {
      if(value !== AreaWeight.MEDIUM) {
        isAllWeightMedium = false;
      }
    })
    this.isResetBtnDisabled = isAllWeightMedium
  }

  selectRoom(room: string) {
    this.selectedRoomName = room
    this.getAreasByRoomName(room)
  }


  changeAreaWeight(area: string, areaWeight: AreaWeight) {
    this.areasWithWeightsMap.set(area, areaWeight)
    this.checkIfAnyAreasWeightHasChanges()
  }

  //Submit
  submitForm() {
    this.submitBtnDisabled = true
    this.areaWeightSettingsService.saveAreaWightSettings(this.projectId, this.selectedRoomName, this.areasWithWeightsMap).subscribe(() => {
        this.notifService.successNotification('Changes have been saved');
        this.updated.emit()
        this.close.emit();
        this.isOpenConfirmModal = false;
        this.submitBtnDisabled = false;
    }, error => this.submitBtnDisabled = false)
  }

  protected readonly String = String;
  protected readonly UserRole = UserRole;
}
