import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Area, AreaStatusDefaultOrder, AreaWeight} from "../../../../../../_models/area";
import {
  areaWeightByEnum,
  modalAnimation,
} from "../../../../../../config/constants";
import {originalOrder} from "../../../../../../_util/utils";
import {NotificationService} from "../../../../../../_services/notification.service";
import {AreasService} from "../../../../../../_services/areas.service";
import {SelectedArea} from '../../types';
import {ProjectCustomColors, ProjectCustomStatuses} from "../../../../../../_models/project";
import {User, UserRole} from "../../../../../../_models/user";
import {AreaFormStore} from "./area-form.store";
import {of, Subject, Subscription, takeUntil} from "rxjs";
import {GlobalStore} from "../../../../../../global.store";
import {ProjectViewStore} from "../../project-view/project-view.store";
import {FormFile} from "../../../../../../_models/material";
import {DatePipe} from "@angular/common";
import {TimePrettifyPipe} from "../../../../../../_pipes/timePrettify.pipe";
import {AreaComment} from "../../../../../../_models/areaComment";
import {RoomAreaHistoryItem} from "../../../../../../_models/roomAreaHistoryItem";

@Component({
  selector: 'app-area-form',
  templateUrl: './area-form.component.html',
  styleUrls: ['./area-form.component.scss'],
  animations: [modalAnimation],
  providers: [AreaFormStore, TimePrettifyPipe],
})
export class AreaFormComponent implements OnInit, OnDestroy {
  @HostBinding('@modalAnimation') modalAnimation: any;

  @Input() projectStatuses: ProjectCustomStatuses[] = [];
  private _selectedArea: SelectedArea;
  private readonly destroy$ = new Subject<void>()

  @Output() updated: EventEmitter<SelectedArea> = new EventEmitter<SelectedArea>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeAreaFile: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild('areaStatusSelectRef') areaStatusSelectRef: ElementRef;
  activeTab: 'activity' | 'history' | 'files' = 'activity';

  startChangeStatusProgress = false;

  isChangeStatusAreaLoading = false
  isLoadingStatus = false;
  isLoadingWeight = false;
  isLoadingStatusProgress = false;

  statusProgressDump = 0;
  selectedFileForPreview: FormFile;
  comments: AreaComment[] = [];
  history: RoomAreaHistoryItem[] = [];


  get selectedArea(): SelectedArea {
    return this._selectedArea;
  }

  @Input()
  set selectedArea(value: SelectedArea) {
    this._selectedArea = value;
    this.statusProgressDump = value.area.statusProgress;
  }

  constructor(public areaFormStore: AreaFormStore,
              private areasService: AreasService,
              private notifService: NotificationService,
              readonly globalStore: GlobalStore) {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.areaFormStore.companyId = companyId);
    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe((projectId) => this.areaFormStore.projectId = projectId);
    this.areaFormStore.comments$.subscribe((data) => this.comments = data);
    this.areaFormStore.history$.subscribe(data => this.history = data);
  }

  ngOnInit() {
    this.updateComments();
    this.updateHistory();
  }


  createComment(comment: AreaComment) {
    this.areaFormStore.addComment(comment);
  }

  editComment(comment: AreaComment) {
    this.areaFormStore.updateComment(comment);
  }

  deleteComment(commentId: number) {
    this.areaFormStore.deleteComment(commentId);
  }



  removeMentionList() {
    document.querySelector('body .ql-mention-list-container')?.remove()
  }

  updateComments() {
    this.loadComments(this._selectedArea.area.id);
  }

  loadComments(areaId: number) {
    this.areaFormStore.loadComments(of({areaId}));
  }

  updateHistory() {
    this.loadHistory(this._selectedArea.area.id);
  }

  loadHistory(areaId: number) {
    this.areaFormStore.loadHistory(of({areaId: areaId}));
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly originalOrder = originalOrder;
  protected readonly AreaStatusDefaultOrder = AreaStatusDefaultOrder;
  protected readonly areaWeightByEnum = areaWeightByEnum;
  protected readonly UserRole = UserRole;
}
