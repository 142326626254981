<div class="backdrop" [attr.showBackground]="!isOpenConfirmModal">
  <div class="modal">
    <form class="form-invite">
      <button type="button" id="btnClose" class="close-modal-btn" (click)="close.emit()">
        <svg class="close-modal-icon">
          <use spriteVersion iconName="modal-close"></use>
        </svg>
      </button>
      <div class="form-inner-wrapper">
        <div class="form-modal-title-wrapper">
          <p class="form-modal-title">Area Weight Settings</p>
        </div>

        <div class="main-fields-wrapper">
          <p class="area-weight-settings-text">
            Select a room name, and you will see all areas belonging to that room.
            Note: Some rooms may be grouped by room name, so you will see all areas from rooms with that name.
          </p>

          <div class="field-wrapper field-wrapper-template-name">
            <label class="form-label">
              <span class="label-text">Room Name</span>
            </label>
            <div class="ng-select-wrapper ng-select-wrapper--wider">
              <ng-select class="custom select-without-absolute-last-option"
                         id="templateSelect"
                         bindLabel="name"
                         bindValue="id"
                         [items]="roomShortList"
                         [clearable]="false"
                         notFoundText="No results matching"
                         [editableSearchTerm]="true"
                         [searchable]="true"
                         (change)="selectRoom($event)"
                         placeholder="Select Rooms Name">
              </ng-select>
            </div>
          </div>
          <ng-scrollbar class="areasFieldList-scroll-wrapper"
                        #areasListWrapperRef
                        [attr.isHidden]="areasList.length === 0"
                        [attr.isLotOfAreas]="areasList.length > 6"
                        [autoHeightDisabled]="true">
            <ul class="areasFieldList templateAreaList"
                #areasListRef
                *ngIf="areasList.length > 0"
                [attr.isLotOfAreas]="areasList.length > 6"
                [attr.withoutMarginBottom]="areasList.length > 0">
              <li tabindex="1" *ngFor="let area of areasWithWeightsMap | keyvalue; let i = index">
                <div class="area-row">
                  <span class="area-name-box" [id]="i">{{area.key}}</span>
                  <app-area-weight-selector [withoutLabel]="true"
                                            [areaWeight]="area.value"
                                            [areasListRef]="areasListRefAsElement"
                                            [areasListLength]="areasList.length"
                                            (changeAreaWeight)="changeAreaWeight(area.key, $event)">
                  </app-area-weight-selector>
                </div>
              </li>
            </ul>
          </ng-scrollbar>

          <p class="no-areas-found-text" *ngIf="this.selectedRoomName && areasList.length === 0">No Areas found</p>

          <p class="info-room-text">
            <svg class="exclamaition-icon" [ngStyle]="{width: '20px'}">
              <use spriteVersion iconName="exclamation-circle"></use>
            </svg>
            <span>
            Changing the weight of an area will change the area weight of all floors and units in the entire project
            </span>
          </p>
        </div>
      </div>

      <div class="form-btn-wrapper">
        <button type="button" class="btn-delete" id="btnResetChangesConfirm" [disabled]="isResetBtnDisabled" (click)="setAllAreaWeightsToMedium(areasList)">Reset to default</button>
        <div class="form-btn-inner-wrapper">
          <button type="button" class="btn-cancel" id="btnCancel" (click)="close.emit()">Cancel</button>
          <button type="button" class="btn-invite" id="btnInvite" [disabled]="areasList.length === 0 || !selectedRoomName" (click)="isOpenConfirmModal = true">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>



<!--Confirm modal-->
<div class="backdrop" [attr.showBackground]="true" [@modalAnimation] *ngIf="isOpenConfirmModal">
  <div class="modal-narrow">
    <form class="form-invite" #form="ngForm" (ngSubmit)="submitForm()">
      <button type="button" id="btnCloseConfirm" class="close-modal-btn" (click)="isOpenConfirmModal = false">
        <svg class="close-modal-icon">
          <use spriteVersion iconName="modal-close"></use>
        </svg>
      </button>
      <div class="form-inner-wrapper">
        <div class="form-modal-title-wrapper">
          <p class="form-modal-title confirmSaveTitle">Are you sure?</p>
        </div>

        <div class="main-fields-wrapper">
          <p class="area-weight-settings-text">
            When saved, the weight will be changed for {{areasList.length}} {{ areasList.length === 1 ? 'area' : 'areas' }}
          </p>
        </div>
      </div>

      <div class="form-btn-wrapper form-btn-wrapper-confirm">
        <button type="button" class="btn-cancel" id="btnCancelConfirm" (click)="isOpenConfirmModal = false">Cancel</button>
        <button type="submit" class="btn-invite" id="btnInviteConfirm" [disabled]="submitBtnDisabled" loader="white" [showLoader]="submitBtnDisabled">{{'Save'}}</button>
      </div>
    </form>
  </div>
</div>

