import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {apiUrl} from "../../environments/environment";
import {ProjectCustomStatuses} from "../_models/project";

@Injectable({
  providedIn: 'root'
})
export class ProjectAreaStatusesService {

  constructor(private http: HttpClient) { }

  getProjectAreaStatuses(projectId: number): Observable<ProjectCustomStatuses[]> {
    return this.http.get<ProjectCustomStatuses[]>(`${apiUrl}/project/${projectId}/areaStatus`, {withCredentials: true})
  }

  saveProjectAreaStatuses(projectId: number, statuses: ProjectCustomStatuses[]): Observable<ProjectCustomStatuses[]> {
    return this.http.put<ProjectCustomStatuses[]>(`${apiUrl}/project/${projectId}/areaStatus`, statuses, {withCredentials: true})
  }

  deleteStatusWithReplacement(projectId: number, deleteStatusId: number, replaceWithId: number, timestamp: number) {
    return this.http.delete<ProjectCustomStatuses[]>(`${apiUrl}/project/${projectId}/areaStatus`,  {params: {deleteStatusId, replaceWithId, timestamp}, withCredentials: true})
  }

}
