import {Project, ProjectCustomStatuses} from "./project";
import {AreaComment} from "./areaComment";
import {FormFile} from "./material";
import {RoomAreaHistoryItem} from "./roomAreaHistoryItem";
import {Room} from "./room";
import {Unit} from "./unit";
import {Floor} from "./floor";
import {Company} from "./company";

export class Area {
  id: number;
  name: string;
  publicId: string;
  orderNumber: number;
  version: number;
  templateVersion: number;
  status: ProjectCustomStatuses;
  areaWeight: AreaWeight = AreaWeight.MEDIUM;
  statusProgress: number;
  comments: AreaComment[] = [];
  files: FormFile[] = [];
  history: RoomAreaHistoryItem[] = [];

  attachmentsAmount: number;
  commentsAmount: number;

  errorName: boolean;
  changed: boolean;

  pp_opacity: any;

  company: Company;
  project: Project;
  floor: Floor;
  unit: Unit;
  room: Room;
}

export class AreaListItem {
  id: number;
  name: string;
  publicId: string;
  areaWeight: AreaWeight;
  status: ProjectCustomStatuses;
  statusProgress: number;
  roomShort: Room;
  unitShort: Unit;
  floorShort: Floor;
  attachmentsAmount: number;
  commentsAmount: number;
}


export class AreasAmount {
  todo: number = 0;
  inProgress: number = 0;
  done: number = 0;
}

export enum AreaStatus {
  TO_DO = "To Do",
  IN_PROGRESS = "In Progress",
  DONE = "Done"
}

export enum AreaStatusDefaultOrder {
  TO_DO = -3,
  IN_PROGRESS = -2,
  DONE = -1
}


export enum AreaWeight {
  NO_WEIGHT = "NO_WEIGHT",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  HUGE = "HUGE",
}
