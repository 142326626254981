import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserRole} from "../../../../../../../../_models/user";
import {GlobalStore} from "../../../../../../../../global.store";
import {SelectedArea} from "../../../../types";
import {ProjectCustomStatuses} from "../../../../../../../../_models/project";
import {AreasService} from "../../../../../../../../_services/areas.service";
import {NotificationService} from "../../../../../../../../_services/notification.service";

@Component({
  selector: 'app-area-details-header',
  templateUrl: './area-details-header.component.html',
  styleUrls: ['./area-details-header.component.scss']
})
export class AreaDetailsHeaderComponent {
  private _selectedArea: SelectedArea;
  isLinkCopied = false;
  @Output() goBack: EventEmitter<any> = new EventEmitter<any>()
  @Input() isAreaDetailsPage = false;
  @Input()
  set selectedArea(value: SelectedArea) {
    this._selectedArea = value;
  }

  get selectedArea(): SelectedArea {
    return this._selectedArea;
  }
  constructor(readonly globalStore: GlobalStore,
              private areaService: AreasService,
              private notifService: NotificationService) {
  }

  generateLink(event: any, publicId: string) {
    this.areaService.generateLink(publicId).subscribe((areaLink: string) => {
      if (areaLink) {
        document.addEventListener('copy', (e: ClipboardEvent) => {
          e.clipboardData.setData('text/plain', (areaLink));
          e.preventDefault();
          document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
        this.notifService.successNotification('Link Copied!')
        this.isLinkCopied = true;
      }
    }, (err) => {
      this.notifService.errorNotification(err)
    })
  }

  protected readonly UserRole = UserRole;
}
