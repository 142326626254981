import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SelectedArea} from "../../../types";
import {GlobalStore} from "../../../../../../../global.store";
import {RoomAreaHistoryItem} from "../../../../../../../_models/roomAreaHistoryItem";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-history-area',
  templateUrl: './history-area.component.html',
  styleUrls: ['./history-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryAreaComponent {
  @Input() selectedArea: SelectedArea;
  private _history: RoomAreaHistoryItem[] = []
  @Input() set history(value: RoomAreaHistoryItem[]) {
    if(value) {
      this._history = value;
      this.sanitizeHtml(value)
    }
  }

  get history() {
    return this._history;
  }

  sanitizeHtml(historyList: RoomAreaHistoryItem[]) {
    this._history = historyList.map(item => {
      return {
        ...item,
        safeHtmlOldValue: item.safeHtmlOldValue = this.domSanitizer.bypassSecurityTrustHtml(item.oldValue),
        safeHtmlNewValue: item.safeHtmlNewValue = this.domSanitizer.bypassSecurityTrustHtml(item.newValue)
      }
    })
  }

  constructor(readonly globalStore: GlobalStore, private domSanitizer: DomSanitizer) {
  }
}
