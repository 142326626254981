import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {of, Subject, takeUntil, tap} from "rxjs";
import {AreasService} from "../../../../../_services/areas.service";
import {Area} from "../../../../../_models/area";
import {SelectedArea} from "../types";
import {GlobalStore} from "../../../../../global.store";
import {ProjectAreaStatusesService} from "../../../../../_services/project-area-statuses.service";
import {ProjectCustomStatuses} from "../../../../../_models/project";
import {UserRole} from "../../../../../_models/user";
import {FormFile} from "../../../../../_models/material";
import {modalAnimation} from "../../../../../config/constants";
import {AreaComment} from "../../../../../_models/areaComment";
import {DomSanitizer} from "@angular/platform-browser";
import {TimePrettifyPipe} from "../../../../../_pipes/timePrettify.pipe";

@Component({
  selector: 'app-area-details-page',
  templateUrl: './area-details-page.component.html',
  styleUrls: ['./area-details-page.component.scss'],
  animations: [modalAnimation],
  providers: [TimePrettifyPipe]
})
export class AreaDetailsPageComponent {
  selectedArea: SelectedArea = new SelectedArea();
  projectStatuses: ProjectCustomStatuses[] = [];
  projectId: number;
  companyId: number;
  selectedFileForPreview: FormFile;
  activeTab: string = 'history';
  areaFiles: FormFile[] = [];

  projectName: string;
  companyName: string;

  private readonly destroy$ = new Subject<void>()
  constructor( private areasService: AreasService,
               private activatedRoute: ActivatedRoute,
               private router: Router,
               public readonly globalStore: GlobalStore,
               private domSanitizer: DomSanitizer,
               private projectAreaStatusesService: ProjectAreaStatusesService) {
    this.globalStore.companyId$.subscribe(companyId => this.companyId = companyId)
    this.activatedRoute.params.pipe(
      tap(params => {
        let queryParams = params['id'];
        if (queryParams) this.getAreaById(params['id']);
      })
    ).subscribe();
    this.globalStore.companyName$.pipe(takeUntil(this.destroy$)).subscribe((companyName) => this.companyName = companyName);
    this.globalStore.projectName$.pipe(takeUntil(this.destroy$)).subscribe((project) => this.projectName = project);
    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe(projectId => {
      this.projectId = projectId
    })
  }

  ngOnInit() {
    if(window.innerWidth < 870) this.activeTab = 'activity'
  }

  getAreaById(areaId: string) {
    this.areasService.getAreaByPublicId(areaId).pipe(
      tap(area => this.globalStore.updateCompanyAndProjectOnAreaPage(of({area})))
    ).subscribe((area) => {
      this.getProjectStatuses()
      this.createSelectedArea(area)
      this.areaFiles = [...this.selectedArea.area.files]
    });
  }

  sanitizeCommentHtml(comments: AreaComment[], area: Area) {
    comments.forEach(e => e.safeHtml = this.domSanitizer.bypassSecurityTrustHtml(e.html))
    area.comments = [...comments]
  }

  getProjectStatuses() {
    this.projectAreaStatusesService.getProjectAreaStatuses(this.projectId).subscribe(statuses => {
      this.projectStatuses = statuses;
    })
  }

  createSelectedArea(area: Area) {
    this.sanitizeCommentHtml(area.comments, area)
    this.selectedArea.area = area;
    this.selectedArea.floorName = area.floor.name
    this.selectedArea.unitName = area.unit.name
    this.selectedArea.roomName = area.room.name;
  }

  updateFiles(obj?: {areaInfo: SelectedArea, fileId: number}) {
    if(obj) this.areaFiles = [...obj.areaInfo.area.files]
    else this.areaFiles = [...this.selectedArea.area.files]
  }

  updateHistory() {
   this.areasService.getAreaHistory(this.selectedArea.area.id).subscribe(data => this.selectedArea.area.history = [...data])
  }

  goBack() {
    this.router.navigate([`/system/company/${this.companyId}/projects/${this.projectId}/areas`]);
  }

  createComment(comment: any) {
    this.selectedArea.area.comments.unshift(comment)
    this.sanitizeCommentHtml(this.selectedArea.area.comments, this.selectedArea.area)
  }

  editComment(comment: any) {
    let commentIdx = this.selectedArea.area.comments.findIndex(el => el.id === comment.id)
    this.selectedArea.area.comments[commentIdx] = {...comment}
    this.sanitizeCommentHtml(this.selectedArea.area.comments, this.selectedArea.area)
  }

  deleteComment(commentId: any) {
    this.selectedArea.area.comments = [...this.selectedArea.area.comments.filter(el => el.id !== commentId)]
  }

  protected readonly UserRole = UserRole;
}
