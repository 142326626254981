<app-sub-header [title]="(globalStore.currentUser$ | async).role === UserRole.ROLE_SUPERADMIN  ? [companyName, projectName, 'Areas'] : [projectName, 'Areas']"></app-sub-header>
<app-project-details-tabs [areaId]="selectedArea?.area?.id"></app-project-details-tabs>
<app-area-details-header [selectedArea]="selectedArea" [isAreaDetailsPage]="true" (goBack)="goBack()"></app-area-details-header>

<div class="area-details-page-sections-wrapper" *ngIf="selectedArea.area">
  <div class="activity-area-wrapper">
    <app-activity-area  [selectedArea]="selectedArea" (updated)="updateHistory()"
                        (fileToPreview)="selectedFileForPreview = $event"
                        [comments]="selectedArea.area.comments"
                        (addComment)="createComment($event)"
                        (updateComment)="editComment($event)"
                        (deleteCommentEmitter)="deleteComment($event)"
                        (addAttachments)="updateFiles()"
                        (removeAttachments)="updateFiles()"
                        [attr.display]='true'>
    </app-activity-area>
  </div>
  <div class="area-details-section-with-tabs-wrapper">
    <app-area-details-section [selectedArea]="selectedArea" [projectStatuses]="projectStatuses"
                              [isAreaDetailsPage]="true" (updated)="globalStore.loadProjectProgress()"
                              (updateHistory)="updateHistory()">
    </app-area-details-section>
    <!--Area Details Tabs-->

    <div class="tabs-with-content-wrapper" [attr.hasContent]="selectedArea.area.history.length !== 0 || selectedArea.area.files.length !== 0">
      <div class="area-details-tabs-list-wrapper">
        <ul class="area-details-tabs-list">
          <li class="area-details-tabs-list__item activity">
            <a class="area-details-tabs-list__link" [class.active]="activeTab === 'activity'" (click)="activeTab = 'activity'">Activity</a>
          </li>
          <li class="area-details-tabs-list__item">
            <a class="area-details-tabs-list__link" [class.active]="activeTab === 'history'" (click)="activeTab = 'history'">History</a>
          </li>
          <li class="area-details-tabs-list__item">
            <a class="area-details-tabs-list__link" [class.active]="activeTab === 'files'" (click)="activeTab = 'files'">Files</a>
          </li>
        </ul>
      </div>

      <app-activity-area  [selectedArea]="selectedArea" (updated)="updateHistory()"
                          (fileToPreview)="selectedFileForPreview = $event"
                          [comments]="selectedArea.area.comments"
                          (addComment)="createComment($event)"
                          (updateComment)="editComment($event)"
                          (deleteCommentEmitter)="deleteComment($event)"
                          [attr.display]="activeTab === 'activity'" [attr.isMobile]="true">
      </app-activity-area>
      <app-history-area [selectedArea]="selectedArea" [history]="selectedArea.area.history" [attr.display]="activeTab === 'history'"></app-history-area>
      <app-files-area [selectedArea]="selectedArea" [files]="areaFiles" (removeAreaFile)="updateFiles($event)" [attr.display]="activeTab === 'files'"></app-files-area>
    </div>
  </div>
</div>


<app-file-preview *ngIf="selectedFileForPreview"
                  [file]="selectedFileForPreview"
                  (close)="selectedFileForPreview = null"
                  [@modalAnimation]>
</app-file-preview>

