 import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {SystemHomeComponent} from "./components/system-home/system-home.component";
import {CompaniesComponent} from "./components/companies/companies.component";
import {ProjectsComponent} from "./components/projects/projects.component";
import {UsersComponent} from "./components/users/users.component";
import {superAdminGuard} from "../../_guards/superadmin.guard";
import {adminGuard} from "../../_guards/admin.guard";
import {AuthGuard} from "../../_guards/AuthGuard";
import {ProjectDetailsComponent} from "./components/project-details/project-details.component";
import {CompanySettingsComponent} from "./components/company-settings/company-settings.component";
import {UserProfileComponent} from "./components/user-profile/user-profile.component";
import {SystemErrorPageComponent} from "./components/system-error-page/system-error-page.component";
import {HomeRedirectComponent} from "./components/home-redirect/home-redirect.component";
import {userDataFilledGuard} from "../../_guards/userDataFilled.guard";
import {ProjectSettingsComponent} from "./components/project-details/project-settings/project-settings.component";
import {TasksComponent} from "./components/project-details/tasks/tasks.component";
import {ProjectViewComponent} from "./components/project-details/project-view/project-view.component";
import {ProjectProgressComponent} from "./components/project-details/project-progress/project-progress.component";
import {
  ActivityAreaComponent
} from "./components/project-details/project-progress/area-form/activity-area/activity-area.component";
import {
  ActivityProjectTableComponent
} from "./components/project-details/activity-project-table/activity-project-table.component";
import {RedirectComponent} from "./components/redirect/redirect.component";
import {ProjectMaterialsComponent} from "./components/project-details/project-materials/project-materials.component";
import {DocumentsListComponent} from "./components/project-details/documents-list/documents-list.component";
import {DrawingsListComponent} from "./components/project-details/drawings-list/drawings-list.component";
import {AreasListComponent} from "./components/project-details/areas-list/areas-list.component";
 import {AreaDetailsPageComponent} from "./components/project-details/area-details-page/area-details-page.component";

const routes: Routes = [
  {path: '',
    component: SystemHomeComponent,
    canActivate: [AuthGuard, userDataFilledGuard],
    children: [
      {path: 'dashboard', component: DashboardComponent, canActivate: [superAdminGuard]},
      {path: '', component: HomeRedirectComponent, pathMatch: 'full'},
      {path: 'companies', component: CompaniesComponent, canActivate: [superAdminGuard]},
      {path: 'company/:id', component: ProjectsComponent},
      {path: 'company/:id/dashboard', component: DashboardComponent},
      {path: 'company/:id/profile', component: UserProfileComponent},
      {path: 'company/:id/projects/:projectId', component: ProjectDetailsComponent, children: [
          {path: 'settings', component: ProjectSettingsComponent},
          {path: 'areas', component: AreasListComponent},
          {path: 'users', component: UsersComponent},
          {path: 'activity', component: ActivityProjectTableComponent},
          {path: 'documents', component: DocumentsListComponent},
          {path: 'drawings', component: DrawingsListComponent},
          {path: 'materials', component: ProjectMaterialsComponent},
          {path: 'tasks', component: TasksComponent},
          {path: 'builder', component: ProjectViewComponent, children: [
              {path: 'activity', component: ActivityAreaComponent},
              {path: 'history', component: ActivityAreaComponent},
              {path: 'files', component: ActivityAreaComponent},
            ]},
          {path: 'progress', component: ProjectProgressComponent},
        ]},
      {path: 'areas/:id', component: AreaDetailsPageComponent},
      {path: 'company/:id/users', component: UsersComponent, canActivate: [adminGuard]},
      {path: 'company/:id/users/:userId', component: UserProfileComponent, canActivate: [adminGuard]},
      {path: 'company/:id/settings', component: CompanySettingsComponent, canActivate: [adminGuard]},
      {path: 'projects', component: ProjectsComponent, canActivate: [superAdminGuard]},
      {path: 'users', component: UsersComponent, canActivate: [superAdminGuard]},
      {path: 'users/:userId', component: UserProfileComponent, canActivate: [adminGuard]},
      {path: 'profile', component: UserProfileComponent, canActivate: [superAdminGuard]},
      {path: 'company/:id/not-found', component: SystemErrorPageComponent},
      {path: 'redirect/notificationSettings', component: RedirectComponent, data: {type: "notificationSettings"}},
      {path: 'not-found', component: SystemErrorPageComponent}
    ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
