<div class="files-container">
  <p *ngIf="files.length === 0 else filesPresent" class="no-changes-placeholder">No files have been added yet</p>
  <ng-template #filesPresent>
    <ul class="comments-items">
      <li *ngFor="let item of files">
        <div class="top-line">
          <div class="initiator">
            <p class="initiator">{{(currentUser | async).id === item.owner.id ? 'You' : item.owner.name}}</p>
            <span>added file</span>
          </div>
          <div class="date-and-actions" [attr.itemTimestamp]="item.timestamp">
            <p class="timestamp">{{item.timestamp | date: 'MMM d, y h:mm a'}}</p>
            <div (appOutsideClick)="editMenuComment.removeAttribute('editMenuCommentOpen')" [enabled]="editMenuComment.hasAttribute('editMenuCommentOpen')" [ngStyle]="{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}">

              <span *ngIf="(currentUser | async).role === UserRole.ROLE_SUPERADMIN || (currentUser | async).id === item.owner.id" class="comments-list__comment-menu-dots" id="menuListEditCommentOpen" (click)="editMenuComment.toggleAttribute('editMenuCommentOpen')"></span>

              <ul class="editMenuList" #editMenuComment>
                <li class="editMenuList__item" id="deleteUnitBtn" (click)="editMenuComment.removeAttribute('editMenuCommentOpen'); deleteFile(item.id)">
                  <svg class="editMenu-icon">
                    <use spriteVersion iconName="trash-delete-icon"></use>
                  </svg>
                  Delete
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="files">
          <div class="file" [attr.wider]="'true'">
            <span class="file-link" (click)="fileToPreview.emit(item)">
              <div class="file-item-inner-wrapper">
                <img *ngIf="renderImage(item.fileUrl)" src="{{item.fileUrl}}"/>
                <span *ngIf="!renderImage(item.name)" class="document-icon-wrapper">
                  <svg width="32" height="32" class="document-icon">
                    <use appDocumentsTypeIcon [documentFile]="item"></use>
                  </svg>
                </span>
                <div class="fileName-with-loader">
                  <p class="fileName-with-timeLeft" ellipsis>{{item.name}}</p>
                  <p class="fileSize" id="fileSize">{{item.sizeBytes | fileSizePrettify}}</p>
                </div>
              </div>
            </span>
          </div>
        </div>
      </li>
    </ul>
  </ng-template>
</div>
