<div class="field-wrapper priority-wrapper">
  <label class="form-label project-optional-label" *ngIf="!withoutLabel">Weight</label>
  <div class="ng-select-wrapper">
    <div class="area-weight-btn-wrapper" #areaWeightSelect [enabled]="areaWeightSelect.hasAttribute('openMenu')" (appOutsideClick)="areaWeightSelect.removeAttribute('openMenu')">
      <button class="area__weight-btn" [attr.value]="selectedArea?.area.areaWeight || areaWeight"  (click)="isLoadingWeight ? null : areaWeightSelect.toggleAttribute('openMenu')" >
        {{selectedArea ? transformTheFirstLetter(selectedArea.area.areaWeight) : transformTheFirstLetter(areaWeight)}}
        <svg *ngIf="!isLoadingWeight" class="area__weight-icon-dropdown" [style.stroke]="'black'">
          <use spriteVersion iconName="arrow-select-area-status"></use>
        </svg>

        <div *ngIf="isLoadingWeight" class="loader-spin">
          <svg [style.stroke]="'black'">
            <use spriteVersion iconName="loader-icon"></use>
          </svg>
        </div>
      </button>
      <div class="area__weight-menu-wrapper" [attr.isBiggerTopPosition]="isAreaDetailsModal" #areasWeightListRef
           (isLowerThenMiddleOfContainer)="areasWeightListRef.style.top='-' + (areasWeightListRef.offsetHeight + 3) + 'px'"
           [containerElement]="areasListRef"
           [isScrollable]="areasListLength > 6"
           *ngIf="areaWeightSelect.hasAttribute('openMenu')">
        <ul class="area__weight-menu">
          <li class="area__weight-menu__item" *ngFor="let weight of areaWeightByEnum | keyvalue: originalOrder" (click)="changeAreaWeight.emit(weight.key); areaWeightSelect.removeAttribute('openMenu')">
            <p class="area__weight-menu__text" [attr.data-val]="weight.value.toUpperCase()" [attr.selectedWeight]="selectedArea ? weight.key === selectedArea.area.areaWeight : weight.key === areaWeight">{{weight.value}}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
