import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User, UserRole} from "../../../../../_models/user";
import {NotificationSettingsService} from "../../../../../_services/notification-settings.service";
import {NotificationSettings} from "../../../../../_models/notificationSettings";
import {error} from "@angular/compiler-cli/src/transformers/util";
import {modalAnimation} from "../../../../../config/constants";
import {NotificationService} from "../../../../../_services/notification.service";

@Component({
  selector: 'app-email-notification-settings',
  templateUrl: './email-notification-settings.component.html',
  styleUrls: ['./email-notification-settings.component.scss'],
  animations: [modalAnimation]
})
export class EmailNotificationSettingsComponent {
  @Input() currentUser: User
  @Output() close: EventEmitter<any> = new EventEmitter<any>()

  submitBtnDisabled = false;
  isSelectAllNotifications = false;
  isSelectOneOfTasksNotifications = false;
  isSelectAllTasksNotifications = false;
  notificationSettings = new NotificationSettings()
  taskReminderMap: Map<number, string> = new Map<number, string>([
    [1, "1 day"],
    [2, "2 days"],
    [3, "3 days"],
  ])

  constructor(private notificationSettingsService: NotificationSettingsService, private notifService: NotificationService) {
    this.notificationSettingsService.getNotificationSettings().subscribe(data => {
      this.notificationSettings = data
      this.switchTasksAllNotifacation()
      this.switchAllNotification()
    })
  }

  switchNotifications(value: string) {
    switch (value) {
      case 'all': {
        this.isSelectAllNotifications = !this.isSelectAllNotifications;
        this.isSelectOneOfTasksNotifications = this.isSelectAllNotifications;
        this.isSelectAllTasksNotifications = this.isSelectAllNotifications;
        this.notificationSettings.newTask =  this.isSelectAllTasksNotifications
        this.notificationSettings.completedTask =  this.isSelectAllTasksNotifications
        this.notificationSettings.taskReminder = this.isSelectAllTasksNotifications
        this.notificationSettings.areaComments = this.isSelectAllNotifications;
        if (this.currentUser.role !== UserRole.ROLE_ADMIN) this.notificationSettings.projects = this.isSelectAllNotifications;

        break
      }
      case 'projects': {
        this.notificationSettings.projects = !this.notificationSettings.projects;
        this.switchAllNotification()
        break
      }
      case 'tasks': {
        this.isSelectAllTasksNotifications = !this.isSelectAllTasksNotifications
        this.notificationSettings.newTask =  this.isSelectAllTasksNotifications
        this.notificationSettings.completedTask =  this.isSelectAllTasksNotifications
        this.notificationSettings.taskReminder = this.isSelectAllTasksNotifications
        this.isSelectOneOfTasksNotifications = this.isSelectAllTasksNotifications
        this.switchAllNotification()
        this.switchTasksAllNotifacation()
        break
      }
      case 'areaComments': {
        this.notificationSettings.areaComments = !this.notificationSettings.areaComments;
        this.switchAllNotification()
        break
      }
    }
  }

  switchTasksNotifications(value: string) {
    switch (value) {
      case 'newTask': {
        this.notificationSettings.newTask = !this.notificationSettings.newTask;
        this.switchTasksAllNotifacation()
        this.switchAllNotification()
        break
      }
      case 'completedTask': {
        this.notificationSettings.completedTask = !this.notificationSettings.completedTask
        this.switchTasksAllNotifacation()
        this.switchAllNotification()
        break
      }
      case 'taskReminder': {
        this.notificationSettings.taskReminder = !this.notificationSettings.taskReminder;
        this.switchTasksAllNotifacation()
        this.switchAllNotification()
        break
      }
    }
  }

  switchAllNotification() {
    if (this.currentUser && this.currentUser.role === UserRole.ROLE_ADMIN) this.isSelectAllNotifications = this.isSelectOneOfTasksNotifications && this.notificationSettings.areaComments;
    else this.isSelectAllNotifications = this.isSelectOneOfTasksNotifications && this.notificationSettings.projects && this.notificationSettings.areaComments;
  }

  switchTasksAllNotifacation() {
    this.isSelectAllTasksNotifications = this.notificationSettings.completedTask && this.notificationSettings.newTask && this.notificationSettings.taskReminder
    this.isSelectOneOfTasksNotifications = this.notificationSettings.completedTask || this.notificationSettings.newTask || this.notificationSettings.taskReminder
  }

  changeTaskReminderPeriod(value: number) {
    this.notificationSettings.taskReminderPeriod = value
  }

  submit() {
    this.notificationSettingsService.updateNotificationSettings(this.notificationSettings).subscribe(data => {
      this.notifService.successNotification('Changes have been saved')
      this.close.emit()
    })
  }

  protected readonly UserRole = UserRole;
}
