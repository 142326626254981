import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-activity-date-filters',
  templateUrl: './activity-date-filters.component.html',
  styleUrls: ['./activity-date-filters.component.scss']
})
export class ActivityDateFiltersComponent {
  dateControl = new FormControl();
  @Output() changeDate: EventEmitter<any> = new EventEmitter<any>()
  @Output() clearDate: EventEmitter<any> = new EventEmitter<any>()

  options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }

  ngOnInit() {
    this.dateControl.valueChanges.subscribe(value => {
      this.changeDate.emit(value)
    });
  }
}
