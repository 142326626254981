import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Notification} from "../_models/notification";
import {apiUrl} from "../../environments/environment";
import {Area, AreaWeight} from "../_models/area";
import {Room} from "../_models/room";

@Injectable({
  providedIn: 'root'
})
export class AreaWeightSettingsService {

  constructor(private http: HttpClient) { }

  getRooms(projectId: number) {
    return this.http.get<Room[]>(`${apiUrl}/project/${projectId}/areaWeightSettings/rooms`, {withCredentials: true})
  }

  getAreasByRoomName(projectId: number, roomName: string) {
    let params = new HttpParams().append('roomName', roomName)
    return this.http.get<string[]>(`${apiUrl}/project/${projectId}/areaWeightSettings/areas`, {params: params, withCredentials: true})
  }

  saveAreaWightSettings(projectId: number, roomName: string, settings: Map<string, AreaWeight>) {
    let params = new HttpParams().append('roomName', roomName)
    const convMap: Record<string, AreaWeight> = {};
    settings.forEach((val: AreaWeight, key: string) => {
      convMap[key] = val;
    });
    return this.http.post(`${apiUrl}/project/${projectId}/areaWeightSettings`, convMap,{params: params, withCredentials: true})
  }
}
