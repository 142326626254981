<app-filter-fields btnText="Create Task" searchPlaceholder="Search by ID, Name" [disabled]="areasPage?.empty && isNoFilteringEnabled()"
                   (inputChanged)="query = $event; getAreasList()"
                   [hideAllBtns]="true"
                   [filtersAmount]="getFiltersActiveAmount()"
                   (clearAllFilters)="clearFilters()">

  <div class="filter-field-wrapper">
    <label class="filter-label">
      Floor:
    </label>
    <div class="ng-select-wrapper">
      <app-floors-selector [optionsList]="floorsShortList"
                           [selectedOption]="selectedFloor"
                           [isClearFieldBtn]="!!selectedFloor"
                           [placeholder]="'Select Floor'"
                           [noOptionFind]="'No Floors'"
                           (changeEl)="selectedFloor = $event; getAreasList()"
      >
      </app-floors-selector>
    </div>
  </div>
  <div class="filter-field-wrapper">
    <label class="filter-label">
      Status:
    </label>
    <app-project-status-selector [isClearFieldBtn]="!!selectedStatus"
                                 [placeholder]="'Select Status'"
                                 [optionsList]="projectStatusesList"
                                 [selectedOption]="selectedStatus"
                                 [noOptionFind]="'No Statuses'"
                                 (changeEl)="selectedStatus = $event; getAreasList()">
    </app-project-status-selector>

  </div>
  <button class="clear-all-filters-btn" (click)="clearFilters()">Clear all filters</button>
</app-filter-fields>
<ng-scrollbar *ngIf="areasPage && !isLoading" externalViewport [attr.isIOS]="(isIOS | async)" [attr.pagination-showed]="this.areasPage.totalElements > 20 && !(isMobile | async)" [autoHeightDisabled]="true" [track]="'all'" class="table-wrapper-with-dots">
  <div class="innerScrollBarWrapper">
    <div [attr.pagination-showed]="this.areasPage.totalElements > 20" class="table-wrapper-with-scroll">
      <div class="table-areas" *ngIf="!areasPage.empty; else emptyList" >
        <ul class="table-areas__header-row">
          <li class="table-areas__id-title fixed-left-column">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['publicId']">
              ID
              <button class="table-sort-icon-btn" (click)="onSortBy('publicId')" [style.opacity]="sorting['publicId'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['publicId'] === SortType.DESC" *ngIf="sorting['publicId']; else grayicon">
                 <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                     <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-areas__floor-name-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['room.unit.floor.name']">
              Floor
              <button class="table-sort-icon-btn" (click)="onSortBy('room.unit.floor.name')" [style.opacity]="sorting['room.unit.floor.name'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['room.unit.floor.name'] === SortType.DESC" *ngIf="sorting['room.unit.floor.name']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-areas__unit-name-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['room.unit.name']">
              Unit
              <button class="table-sort-icon-btn" (click)="onSortBy('room.unit.name')" [style.opacity]="sorting['room.unit.name'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['room.unit.name'] === SortType.DESC" *ngIf="sorting['room.unit.name']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-areas__room-name-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['room.name']">
              Room
              <button class="table-sort-icon-btn" (click)="onSortBy('room.name')" [style.opacity]="sorting['room.name'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['room.name'] === SortType.DESC" *ngIf="sorting['room.name']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-areas__name-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['templateArea.name']">
              Area
              <button class="table-sort-icon-btn" (click)="onSortBy('templateArea.name')" [style.opacity]="sorting['templateArea.name'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['templateArea.name'] === SortType.DESC" *ngIf="sorting['templateArea.name']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-areas__status">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['status']">
              Status
              <button class="table-sort-icon-btn" (click)="onSortBy('status')" [style.opacity]="sorting['status'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['status'] === SortType.DESC" *ngIf="sorting['status']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-areas__weight-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['areaWeight']">
              Weight
              <button class="table-sort-icon-btn" (click)="onSortBy('areaWeight')" [style.opacity]="sorting['areaWeight'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['areaWeight'] === SortType.DESC" *ngIf="sorting['areaWeight']; else grayicon">
                 <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-areas__actions no-right-border"></li>
        </ul>

        <ul class="table-body-list">
          <li *ngFor="let area of areasPage.content" [id]="area.id" class="table-areas__row">
            <ul class="item-info-list">
              <li class="area-id fixed-left-column" (click)="$event.stopPropagation()" [routerLink]="['/system/areas', area.publicId]">
                  <span class="item-code-way">
                    <span class="list-name-wrapper">
                      {{area.publicId}}
                    </span>
                  </span>
              </li>
              <li class="area-floor-name">
                <div class="area-floor-name__wrapper">
                  <div class="list-name-wrapper">
                      <span>
                      {{area.floorShort.name}}
                      </span>
                  </div>
                </div>
              </li>
              <li class="area-floor-name">
                <div class="area-floor-name__wrapper">
                  <div class="list-name-wrapper">
                      <span>
                      {{area.unitShort.name}}
                      </span>
                  </div>

                </div>
              </li>
              <li class="area-floor-name">
                <div class="area-floor-name__wrapper">
                  <div class="list-name-wrapper">
                      <span>
                      {{area.roomShort.name}}
                      </span>
                  </div>

                </div>
              </li>
              <li class="area-name" (click)="$event.stopPropagation()" [routerLink]="['/system/areas', area.publicId]">
                <div class="area-name__wrapper">
                  <div class="list-name-wrapper">
                      <span>
                      {{area.name}}
                      </span>

                    <div class="area-file-with-description-icon">
                      <svg class="attachment-icon" *ngIf="area?.attachmentsAmount > 0">
                        <use spriteVersion iconName="attachment"></use>
                      </svg>
                      <svg class="description-icon" *ngIf="area?.commentsAmount > 0">
                        <use spriteVersion iconName="description"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </li>

              <li class="area-status-td">
                <div class="area-status-inner-wrapper">
                  <button class="area-status-btn" ellipsis [ngStyle]="{'background-color': area.status.color, 'color': addColorToSelectValue(area.status)}">
                    {{area.status.name}}
                  </button>
                  {{area.statusProgress}}%
                </div>
              </li>

              <li class="area-weight">
                <div class="area-weight-inner-wrapper">
                  <svg class="weight-icon" *ngIf="area.areaWeight && area.areaWeight !== AreaWeight.NO_WEIGHT">
                    <use [attr.href]="'assets/sprite.svg#area-flag-weight-' + area.areaWeight.toLowerCase()"></use>
                  </svg>
                  {{areaWeightByEnum.get(area.areaWeight)}}
                </div>
                <!--                <span *ngIf="!area.weight || area.weight === Taskweight.NO_weight">-</span>-->
              </li>
              <li tabindex="1" class="last-right-column-without-dots" id="dotsMenu"></li>
            </ul>
          </li>

          <app-pagination *ngIf="this.areasPage.totalElements > 20 && (isMobile | async)" [isLoading]="!areasPage" [paginationInfo]="this.areasPage" [showAmountForMobile]="pageSizeOnMobile" (onChangeShowNumber)="changeShowNumber($event)" (changePage)="changeCurrentPage($event)"></app-pagination>
        </ul>
      </div>
    </div>

  </div>
</ng-scrollbar>

<div *ngIf="!areasPage || isLoading" class="table-wrapper-with-dots loading">
  <app-loader></app-loader>
</div>


<app-pagination *ngIf="this.areasPage?.totalElements > 20 && !(isMobile | async)" [showAmountForDesk]="pageSizeOnDesktop" [isLoading]="!areasPage" [paginationInfo]="this.areasPage" (onChangeShowNumber)="changeShowNumber($event)" (changePage)="changeCurrentPage($event)"></app-pagination>


<ng-template #emptyList>
  <div class="empty-list-wrapper">
    <p class="empty-list-text" *ngIf="isNoFilteringEnabled() && !hideCompleted && areasPage.empty; else notFound">The list of areas is empty.</p>
    <ng-template #notFound>
      <p class="empty-list-text">No Areas found</p>
    </ng-template>
    <img class="empty-list-icon" src="assets/images/empty-list.png" alt="empty-list">
  </div>
</ng-template>




