<div class="go-back-btn-with-area-header-wrapper" *ngIf="selectedArea">
  <button class="go-back-btn" *ngIf="isAreaDetailsPage" (click)="goBack.emit()">
    <svg class="go-back-arrow">
      <use spriteVersion iconName="go-back-arrow"></use>
    </svg>
  </button>
  <p class="area-details-way-wrapper">
    <span class="area-details-way-hidden">
      <ng-template [ngIf]="(globalStore.currentUser$ | async).role === UserRole.ROLE_SUPERADMIN">
        <span class="form-modal-way company">{{globalStore.companyName$ | async}}</span>
        /
      </ng-template>
      <span class="area-details-way project">{{globalStore.projectName$ | async}}</span>
      /
    </span>
    <span class="area-details-way">{{selectedArea?.floorName}}</span>
    /
    <span class="area-details-way">{{selectedArea?.unitName}}</span>
    /
    <span class="area-details-way">{{selectedArea?.roomName}}</span>
    /
    <span class="area-details-way">{{selectedArea?.area?.name}}</span>
    /
    <span class="area-details-way area-code" [routerLink]="['/system/areas', selectedArea?.area?.publicId]">{{selectedArea?.area?.publicId}}</span>

    <svg class="area-link-icon" *ngIf="!isLinkCopied" (click)="generateLink($event, selectedArea?.area?.publicId)">
      <use spriteVersion iconName="area-link-icon"></use>
    </svg>

    <svg *ngIf="isLinkCopied" class="area-link-icon-success">
      <use spriteVersion iconName="area-link-icon-success"></use>
    </svg>
  </p>
</div>
<div class="area-details-name-wrapper" *ngIf="selectedArea">
  <p class="area-details-name">{{selectedArea?.area?.name}}</p>
</div>
