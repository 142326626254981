<div class="ng-select-wrapper">
  <div class="material-unit-btn-wrapper" #materialSelect
       [enabled]="materialSelect.hasAttribute('openMenu')"
       (appOutsideClick)="materialSelect.removeAttribute('openMenu'); closeSelect();">
      <input class="form-input-modal"
             id="inputElement"
             formInput="NAME"
             [placeholder]="selectedOption ? '' : placeholder"
             (input)="searchEl($event)"
             #input
             (click)="materialSelect.setAttribute('openMenu', 'true')">
      <svg class="material__unit-icon-dropdown"
           *ngIf="!isClearFieldBtn"
           (click)="toggleDropDownMenu(materialSelect)">
        <use spriteVersion iconName="arrow-select-area-status"></use>
      </svg>
      <svg class="clear-field-btn-icon"
           *ngIf="isClearFieldBtn"
           (click)="clearField()">
        <use spriteVersion iconName="close-icon"></use>
      </svg>

    <span class="selected-option"
          *ngIf="selectedOption"
          [ngStyle]="{'background-color': selectedOption.color, 'color': addColorToSelectValue(selectedOption)}">
      {{selectedOption.name}}
    </span>

    <div class="material__unit-menu-wrapper">
      <ul class="material__unit-menu">
        <li class="material__unit-menu__item" *ngFor="let status of filteredElementList; let idx = index" (click)="changeEl.emit(status); onChange(status); materialSelect.removeAttribute('openMenu')">
          <p class="material__unit-menu__text"
             [ngStyle]="{'background-color': status.color, 'color': addColorToSelectValue(status)}"
             [attr.selectedOption]="idx === optionsList.indexOf(selectedOption)">
            {{status.name}}
          </p>
        </li>
        <li class="material__unit-menu__item" *ngIf="filteredElementList.length === 0">
          <span class="material__unit-menu__text" [attr.noOptionFound]="filteredElementList.length === 0">{{ noOptionFind }}</span>
        </li>
      </ul>
    </div>
  </div>

</div>
