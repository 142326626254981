<div class="activity-area-attachments-with-comment-wrapper">
  <app-area-add-files [selectedArea]="selectedArea" (updateAttachments)="updateAttachments($event)"
                      (fileToPreview)="fileToPreview.emit($event)"
                      (addAttachments)="addAttachments.emit($event)" (removeAttachments)="removeAttachments.emit($event)">
  </app-area-add-files>
  <div class="activity-area-wrapper" [attr.isPadding]="comments.length === 0 ? 'false' : 'true'">
      <div class="scroll-comments-wrapper" [attr.isPadding]="comments.length === 0 ? 'false' : 'true'">
          <app-area-editor [selectedArea]="selectedArea" (createComment)="createComment($event)"></app-area-editor>
          <p class="activity-area-wrapper__no-comments" *ngIf="comments.length === 0">No comments or status changes have been added yet</p>

          <ul class="comments-list" *ngIf="comments.length !== 0" [style.min-height]="comments.length === 1 ? '150px' : '250px'">
              <li class="comments-list__item" *ngFor="let comment of comments" [style.padding]="editorWrapper.hasAttribute('open') ? '16px 0' : '0'">
                  <div class="comments-list__item--inner" *ngIf="!editorWrapper.hasAttribute('open')">
                      <div class="comments-list__person">
                          <div class="comments-list__person--inner-wrapper">
                              {{currentUser.id === comment.author.id ? 'You' : comment.author.name}}
                              <span class="comments-list--light-gray">added a comment</span>
                          </div>
                          <div class="comments-list__person--inner-wrapper-right">
                              <span class="comments-list--light-gray">{{comment.timestamp | date: 'MMM d, y h:mm a'}}</span>
                              <div (appOutsideClick)="editMenuComment.removeAttribute('editMenuCommentOpen')" [enabled]="editMenuComment.hasAttribute('editMenuCommentOpen')" [ngStyle]="{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}">

                                  <span *ngIf="currentUser.role === UserRole.ROLE_SUPERADMIN || currentUser.id === comment.author.id" class="comments-list__comment-menu-dots" id="menuListEditCommentOpen" (click)="editMenuComment.toggleAttribute('editMenuCommentOpen'); scrollTo(editMenuComment)"></span>

                                  <ul class="editMenuList" #editMenuComment>
                                      <li *ngIf="currentUser.id === comment.author.id" class="editMenuList__item" id="editUnitBtn" (click)="editMenuComment.removeAttribute('editMenuCommentOpen'); editorWrapper.setAttribute('open', 'true'); scrollTo(editorWrapper)">
                                          <svg class="editMenu-icon">
                                              <use spriteVersion iconName="edit-icon"></use>
                                          </svg>
                                          Edit
                                      </li>
                                      <li class="editMenuList__item" id="deleteUnitBtn" (click)="editMenuComment.removeAttribute('editMenuCommentOpen'); deleteComment(comment)">
                                          <svg class="editMenu-icon">
                                              <use spriteVersion iconName="trash-delete-icon"></use>
                                          </svg>
                                          Delete
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <div class="comments-list__comment-text" (click)="commentClicked($event)" [attr.data-comment-id]="comment.id" [innerHTML]="comment.safeHtml"></div>
                      <ul class="files-list">
                          <li class="file-item" *ngFor="let file of comment.files" [attr.wider]="comment.files.length > 1 ? 'false' : 'true'">
                              <span class="file-link" (click)="fileToPreview.emit(file)">
                                  <div class="file-item-inner-wrapper">
                                    <img [src]="file.fileUrl" class="file-img" *ngIf="isFileCanBePreviewed(file.name)">
                                    <span *ngIf="!isFileCanBePreviewed(file.name)" class="document-icon-wrapper">
                                      <svg width="32" height="32" class="document-icon">
                                        <use appDocumentsTypeIcon [documentFile]="file"></use>
                                      </svg>
                                    </span>
                                    <div class="fileName-with-loader">
                                        <p class="fileName-with-timeLeft">{{file.name | sliceText: 40}}</p>
                                        <p class="fileSize" id="fileSize">{{file.sizeBytes | fileSizePrettify}}</p>
                                    </div>
                                  </div>
                              </span>
                          </li>
                      </ul>
                  </div>
                  <div class="editorWrapper" [id]="'comment-editor-' + comment.id" #editorWrapper (appOutsideClick)="editorWrapper.toggleAttribute('open')" [enabled]="editorWrapper.hasAttribute('open')">
                      <app-area-editor [@fadeIn] *ngIf="editorWrapper.hasAttribute('open')" [selectedArea]="selectedArea" [comment]="comment" (editComment)="editComment($event)" (close)="editorWrapper.removeAttribute('open')"></app-area-editor>
                  </div>
              </li>
          </ul>
      </div>
  </div>
</div>
