<app-sub-header [title]="[currentUser.role === UserRole.ROLE_SUPERADMIN ? (globalStore.companyName$ | async) : '', 'Projects']"></app-sub-header>

<app-filter-fields btnText="Create Project" [searchPlaceholder]="companyId ? 'Search by ID, Name, or Project#' : 'Search by ID, Name, Project#, or Company'" (hideCompleted)="hideCompleted = !hideCompleted; subscribeToGetProjectsList()" [disabled]="isNoFilteringEnabled() && !hideCompleted && projectsPage?.empty" (openFormBtnClick)="projectFormOpened = true"
                   (inputChanged)="onInputChange($event)"
                   [filtersAmount]="getFiltersActiveAmount()" hideButtonText="Hide completed projects" showButtonText="Show completed projects"
                   (deleteSelectedItem)="deletingPopupOpened = true"
                   (cancelSelectedItem)="multiSelectedProjects = []; isMultiSelectAll = false"
                   [selectedItemsAmount]="multiSelectedProjects.length"
                   (clearAllFilters)="clearFilters()" (export)="export()">
  <div class="filter-field-wrapper" *ngIf="!companyId">
    <label class="filter-label">
      Filter by Company:
    </label>
    <div class="ng-select-wrapper">
      <ng-select [items]="companiesList"
                 [(ngModel)]="selectedProjectCompanyName"
                 notFoundText="No results matching"
                 [editableSearchTerm]="true"
                 (change)="subscribeToGetProjectsList()"
                 placeholder="Add Company Name">
      </ng-select>
    </div>
  </div>
  <div class="filter-field-wrapper">
    <label class="filter-label">
      Filter by Client:
    </label>
    <div class="ng-select-wrapper">
      <ng-select [items]="clientsList"
                 [(ngModel)]="selectedClientName"
                 notFoundText="No results matching"
                 [editableSearchTerm]="true"
                 (change)="subscribeToGetProjectsList()"
                 placeholder="Add Client">
      </ng-select>
    </div>
  </div>
  <button class="clear-all-filters-btn" (click)="clearFilters()">Clear all filters</button>
</app-filter-fields>
<ng-scrollbar *ngIf="projectsPage && !isLoading" [attr.isIOS]="(isIOS | async)" [attr.pagination-showed]="this.projectsPage.totalElements > 20 && !(isMobile | async)" [autoHeightDisabled]="true" [track]="'all'" class="table-wrapper-with-dots">
  <div class="innerScrollBarWrapper">
    <div [attr.pagination-showed]="this.projectsPage.totalElements > 20" class="table-wrapper-with-scroll">
      <div class="table-projects" *ngIf="!projectsPage.empty; else emptyList">
        <ul class="table-projects__header-row">
          <li class="table-projects__multiselected" *ngIf="multiSelectEnabled && multiSelectedProjects.length > 0">
            <div class="checkbox-container">
              <label class="checkbox-label">
                <input type="checkbox" [checked]="isMultiSelectAll" (change)="toggleCheckAll()" [id]="'selectItemCheckbox'" class="visually-hidden"/>
                <span class="checkbox"></span>
              </label>
            </div>
          </li>
          <li class="table-projects__id-title fixed-left-column" [attr.isMultiselect]="multiSelectEnabled && multiSelectedProjects.length > 0">
              <span class="table-title-wrapper" [attr.shouldBlack]="sorting['publicId']">
                ID
                <button class="table-sort-icon-btn" (click)="onSortBy('publicId')" [style.opacity]="sorting['publicId'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['publicId'] === SortType.DESC" *ngIf="sorting['publicId']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                       <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-projects__name-title fixed-left-column-name" [attr.isMultiselect]="multiSelectEnabled && multiSelectedProjects.length > 0">
              <span class="table-title-wrapper" [style.min-width]="'142px'"  [attr.shouldBlack]="sorting['name']">
                Project Name
                <button class="table-sort-icon-btn" (click)="onSortBy('name')" [style.opacity]="sorting['name'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['name'] === SortType.DESC" *ngIf="sorting['name']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                      <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-projects__status-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['status']">
               Status
                <button class="table-sort-icon-btn" (click)="onSortBy('status')" [style.opacity]="sorting['status'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['status'] === SortType.DESC" *ngIf="sorting['status']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                     <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-projects__project-number-title" [style.width]="'96px'">
              <span class="table-title-wrapper" [style.width]="'70px'" [attr.shouldBlack]="sorting['number']">
                <p class="text-ellipsis-wrapper" title="Project #">#</p>
                <button class="table-sort-icon-btn" (click)="onSortBy('number')" [style]="{'margin-left': '0', 'opacity': sorting['number'] ? 1 : ''}" >
                  <svg class="table-sort" [attr.rotate]="sorting['number'] === SortType.DESC" *ngIf="sorting['number']; else grayicon">
                    <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                      <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>

          <li class="table-projects__project-number-title">
              <span class="table-title-wrapper" [style.width]="'70px'" [attr.shouldBlack]="sorting['publicKey']">
                <p class="text-ellipsis-wrapper" title="Project Code">Code</p>
                <button class="table-sort-icon-btn" (click)="onSortBy('publicKey')" [style]="{'margin-left': '0', 'opacity': sorting['publicKey'] ? 1 : ''}">
                  <svg class="table-sort" [attr.rotate]="sorting['publicKey'] === SortType.DESC" *ngIf="sorting['publicKey']; else grayicon">
                     <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                     <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>

          <li class="table-projects__company-name-title" *ngIf="!companyId">
              <span class="table-title-wrapper" [style.min-width]="'99px'" [attr.shouldBlack]="sorting['company.name']">
                Company

                <button class="table-sort-icon-btn" (click)="onSortBy('company.name')" [style.opacity]="sorting['company.name'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['company.name'] === SortType.DESC" *ngIf="sorting['company.name']; else grayicon">
                    <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                      <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>

          <li class="table-projects__client-title">
              <span class="table-title-wrapper" [style.min-width]="'99px'" [attr.shouldBlack]="sorting['clientName']">
               Client
                <button class="table-sort-icon-btn" (click)="onSortBy('clientName')" [style.opacity]="sorting['clientName'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['clientName'] === SortType.DESC" *ngIf="sorting['clientName']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                     <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-projects__start-date-title">
              <span class="table-title-wrapper" [style.min-width]="'99px'" [attr.shouldBlack]="sorting['startDate']">
               Start Date

                <button class="table-sort-icon-btn" (click)="onSortBy('startDate')" [style.opacity]="sorting['startDate'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['startDate'] === SortType.DESC" *ngIf="sorting['startDate']; else grayicon">
                    <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                      <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-projects__end-date-title">
              <span class="table-title-wrapper" [style.min-width]="'99px'" [attr.shouldBlack]="sorting['endDate']">
               End Date

                <button class="table-sort-icon-btn" (click)="onSortBy('endDate')" [style.opacity]="sorting['endDate'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['endDate'] === SortType.DESC" *ngIf="sorting['endDate']; else grayicon">
                    <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                      <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>

          <li class="table-projects__members-title" [ngClass]="currentUser.role !== UserRole.ROLE_EMPLOYEE && currentUser.role !== UserRole.ROLE_PROJECT_MANAGER ? '' : 'no-right-border'">

              <span class="table-title-wrapper" [style.min-width]="'59px'" [attr.shouldBlack]="sorting['membersCount']">
                <p class="text-ellipsis-wrapper">Members</p>
                <button class="table-sort-icon-btn" (click)="onSortBy('membersCount')" [style]="{'margin-left': '0', 'opacity': sorting['membersCount'] ? 1 : ''}">
                  <svg class="table-sort" [attr.rotate]="sorting['membersCount'] === SortType.DESC" *ngIf="sorting['membersCount']; else grayicon">
                    <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                     <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-projects__actions no-right-border fixed-column" *ngIf="currentUser.role !== UserRole.ROLE_EMPLOYEE && currentUser.role !== UserRole.ROLE_PROJECT_MANAGER"></li>
        </ul>

        <ul class="table-body-list">
          <li *ngFor="let project of projectsPage.content; index as i" [id]="project.id" class="table-projects__row" [attr.multiselected]="multiSelectedProjects.includes(project.id)" [ngStyle]="multiSelectedProjects.includes(project.id) ? {'background-color': '#F7F7F8'} : {}">
            <ul class="item-info-list">
              <li class="company-id project-multiselect-cell" *ngIf="multiSelectEnabled && multiSelectedProjects.length > 0">
                <div class="checkbox-container">
                  <label class="checkbox-label">
                    <input type="checkbox" [checked]="multiSelectedProjects.includes(project.id)" (change)="toggleCheck(project.id)" [id]="'selectItemCheckbox-' + i" class="visually-hidden"/>
                    <span class="checkbox"></span>
                  </label>
                </div>
              </li>
              <li class="company-id fixed-left-column" [attr.isMultiselect]="multiSelectEnabled && multiSelectedProjects.length > 0" [routerLink]="['/system/company', project.company.id, 'projects', project.id, 'builder']" [ngStyle]="multiSelectedProjects.includes(project.id) ? {'background-color': '#F7F7F8'} : {}">
                <span class="item-code-way">
                  <span class="text-ellipsis-wrapper list-name-wrapper" [title]="project.publicId">
                    {{project.publicId}}
                  </span>
                </span>
              </li>
              <li class="company-name fixed-left-column-name" [attr.isMultiselect]="multiSelectEnabled && multiSelectedProjects.length > 0"  [routerLink]="['/system/company', project.company.id, 'projects', project.id, 'builder']" [ngStyle]="multiSelectedProjects.includes(project.id) ? {'background-color': '#F7F7F8'} : {}">
                <div class="project-name__wrapper">
                  <span class="list-name-wrapper" [title]="project.name">
                    {{project.name}}
                  </span>
                </div>
              </li>
              <li class="status-item">
                <div class="project-status-wrapper">
                  <div class="status-bar-colored" [attr.status]="project.status" [style.background]="'linear-gradient(to right, #FF4E00 0%, #FF4E00 ' + project.status + '%, #F0F2F6 ' + project.status + '%), #F0F2F6 100%'"></div>
                  {{project.status}} %
                </div>
              </li>
              <li class="project-number-item"><p class="text-ellipsis-wrapper project-number-wrapper" [title]="project.number">{{project.number}}</p></li>
              <li class="project-code-item"><p class="text-ellipsis-wrapper project-publicKey-wrapper" [title]="project.publicKey">{{project.publicKey}}</p></li>
              <li class="company-name-project-item" *ngIf="!companyId">
                <ng-template [nestedEllipsis]>
                  <span class="ellipsis-text" [title]="project.company.name">{{project.company.name}}</span>
                </ng-template>
              </li>
              <li class="client-name">
                <ng-template [nestedEllipsis]>
                  <span class="ellipsis-text">{{project.clientName ? project.clientName : '-'}}</span>
                </ng-template>
              </li>
              <li class="start-date-item">
                <div class="date-box">
                  <ng-container *ngIf="project.startDate">
                    <svg class="calendar-icon-table">
                      <use spriteVersion iconName="calendar"></use>
                    </svg>
                    {{project.startDate | dateFormatter}}
                  </ng-container>
                  <ng-container *ngIf="!project.startDate">-</ng-container>
                </div>
              </li>
              <li class="end-date-item">
                <div class="date-box">
                  <ng-container *ngIf="project.endDate">
                    <svg class="calendar-icon-table">
                      <use spriteVersion iconName="calendar"></use>
                    </svg>
                    {{project.endDate | dateFormatter}}
                  </ng-container>
                  <ng-container *ngIf="!project.endDate">-</ng-container>
                </div>
              </li>
              <li class="members-item">{{project.membersCount}}</li>
              <li tabindex="1" class="dots-actions fixed-column" id="dotsMenu"  *ngIf="currentUser.role !== UserRole.ROLE_EMPLOYEE && currentUser.role !== UserRole.ROLE_PROJECT_MANAGER"  #projectDots (click)="projectDots.toggleAttribute('editMenuOpen'); $event.stopPropagation()" [enabled]="projectDots.hasAttribute('editMenuOpen')" (appOutsideClick)="projectDots.removeAttribute('editMenuOpen')">
                <ul class="editMenuList" #editMenuList (isLowerThenMiddleOfScreen)="editMenuList.style.top='-' + editMenuList.offsetHeight + 'px'" [@fadeIn] *ngIf="projectDots.hasAttribute('editMenuOpen')">
                  <li class="editMenuList__item" id="selectItem" (click)="toggleCheck(project.id)">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="select-item-icon"></use>
                    </svg>
                    Select
                  </li>
                  <li class="editMenuList__item"  id="editItem" (click)="getProjectById(project.id); projectFormOpened = true">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="edit-icon"></use>
                    </svg>
                    Edit
                  </li>
                  <li class="editMenuList__item"  id="deleteItem" (click)="selectedProject = project; deletingPopupOpened = true">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="trash-delete-icon"></use>
                    </svg>
                    Delete
                  </li>
                </ul>
              </li>

              <ng-template #dotsMock><li class="dots-actions-mock fixed-column"></li></ng-template>
            </ul>
          </li>

          <app-pagination *ngIf="this.projectsPage.totalElements > 20 && (isMobile | async)" [isLoading]="!projectsPage" [paginationInfo]="this.projectsPage" [showAmountForMobile]="pageSizeMobile" (onChangeShowNumber)="changeShowNumber($event)" (changePage)="changeCurrentPage($event)"></app-pagination>
        </ul>
      </div>
    </div>

  </div>
</ng-scrollbar>

<div *ngIf="!projectsPage || isLoading" class="table-wrapper-with-dots loading">
  <app-loader></app-loader>
</div>

<app-pagination *ngIf="this.projectsPage?.totalElements > 20 && !(isMobile | async)" [showAmountForDesk]="pageSizeDesktop" [isLoading]="!projectsPage" [paginationInfo]="this.projectsPage" (onChangeShowNumber)="changeShowNumber($event)" (changePage)="changeCurrentPage($event)"></app-pagination>

<ng-template #emptyList>
  <div class="empty-list-wrapper">
    <p class="empty-list-text" *ngIf="isNoFilteringEnabled() && !hideCompleted && projectsPage.empty; else notFound">The list of projects is empty.</p>
    <ng-template #notFound>
      <p class="empty-list-text">No Projects found</p>
    </ng-template>
    <img class="empty-list-icon" src="assets/images/empty-list.png" alt="empty-list">
  </div>
</ng-template>

<div class="backdrop manual" [hidden]="!deletingPopupOpened" [attr.show]="deletingPopupOpened">
  <div [enabled]="deletingPopupOpened" (appOutsideClick)="closeDeletingPopup()" class="modal-narrow">
    <button type="button" id="btnCloseModal" class="close-modal-btn close-modal-deleting" (click)="closeDeletingPopup()">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>

    <div class="confirmDeleting-wrapper">
      <p class="confirmDeleting-title" *ngIf="selectedProject">Delete the Project “{{selectedProject.name}}”?</p>
      <p class="confirmDeleting-title" *ngIf="!selectedProject">Delete All Projects?</p>
      <p class="confirmDeleting-text">
        Do you really want to delete {{selectedProject ? 'this project':  'these projects'}}? All information about
        {{selectedProject ? 'the project, its plan and access to it' : 'these projects, their plan and access to them'}} will no longer be available
      </p>
    </div>

    <div class="form-btn-wrapper">
      <button type="button" id="btnCancel" class="btn-cancel" (click)="closeDeletingPopup()">Cancel</button>
      <button type="submit" id="btnDeleteProject" class="btn-delete" [disabled]="deletingBtnDisabled" (click)="selectedProject ? deleteProject(selectedProject.id, selectedProject.company.id) : deleteSelected(); closeDeletingPopup()">
        <svg class="btn-delete__icon">
          <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        Delete {{selectedProject ? 'Project' : 'Projects'}}
      </button>
    </div>
  </div>
</div>

<app-project-form *ngIf="projectFormOpened" (close)="projectFormOpened = false; selectedProject = undefined" (created)="projectCreated($event)" (updated)="projectUpdated($event)" [project]="selectedProject"></app-project-form>
