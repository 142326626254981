<div class="ng-select-wrapper">
  <div class="material-unit-btn-wrapper" #materialSelect
       [enabled]="materialSelect.hasAttribute('openMenu')"
       (appOutsideClick)="materialSelect.removeAttribute('openMenu'); closeSelect();">
    <input class="form-input-modal"
           id="inputElement"
           formInput="NAME"
           [placeholder]="placeholder"
           (input)="searchEl($event)"
           [value]="inputValue ? inputValue : null"
           #input
           (click)="materialSelect.setAttribute('openMenu', 'true')">
    <svg class="material__unit-icon-dropdown"
         *ngIf="!isClearFieldBtn"
         (click)="toggleDropDownMenu(materialSelect)">
      <use spriteVersion iconName="arrow-select-area-status"></use>
    </svg>
    <svg class="clear-field-btn-icon"
         *ngIf="isClearFieldBtn"
         (click)="clearField()">
      <use spriteVersion iconName="close-icon"></use>
    </svg>

    <div class="material__unit-menu-wrapper">
      <ul class="material__unit-menu">
        <li class="material__unit-menu__item" *ngFor="let option of filteredElementList; let idx = index" (click)="changeEl.emit(option); onChange(option); materialSelect.removeAttribute('openMenu')">
          <p class="material__unit-menu__text"
             [attr.selectedOption]="idx === optionsList.indexOf(selectedOption)">
            {{option.name}}
          </p>
        </li>
        <li class="material__unit-menu__item" *ngIf="filteredElementList.length === 0">
          <span class="material__unit-menu__text" [attr.noOptionFound]="filteredElementList.length === 0">{{ noOptionFind }}</span>
        </li>
      </ul>
    </div>
  </div>

</div>
