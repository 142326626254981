import {Directive, ElementRef, Inject, Input} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {HttpClient} from "@angular/common/http";

@Directive({
  selector: '[loader]'
})
export class LoaderDirective {
  targetEl: HTMLElement = this.el.nativeElement;
  targetElColor: string;
  loaderEl: HTMLElement;
  _loaderColor: string;
  timeout: any
  timeoutForRemoveLoader: any;

  @Input()
  set showLoader(value: boolean) {
    if (value) {
      if(this.loaderEl?.firstChild) this.addLoader()
      else {
        if(this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.addLoader()
        }, 200)
      }
    }
    else this.removeLoader();
  }

  @Input('loader') set loaderColor(val: 'white' | 'black') {
    this._loaderColor = val;
  }

  constructor(private el: ElementRef, @Inject(DOCUMENT) private document: Document, private http: HttpClient) {
    this.createLoader();
    this.targetEl.style.position = 'relative';
  }

  changeColor(newColor: string) {
    this.targetEl.style.color = newColor;
  }

  addLoader() {
    (this.loaderEl.firstChild as HTMLElement).setAttribute('fill', this._loaderColor);
    (this.loaderEl.firstChild as HTMLElement).setAttribute('stroke', this._loaderColor);

    this.targetElColor = this.targetEl.style.color;
    this.changeColor('transparent');
    this.targetEl.appendChild(this.loaderEl);
  }

  removeLoader() {
    if(this.timeoutForRemoveLoader) clearTimeout(this.timeoutForRemoveLoader)
    this.timeoutForRemoveLoader = setTimeout(() => {
      if (this.loaderEl) {
        if(this.timeout) clearTimeout(this.timeout)
        this.changeColor(this.targetElColor);
        this.targetElColor = undefined;
        this.loaderEl.remove();
      }
    }, 200)
  }

  createLoader() {
    this.http.get('assets/icons/loader-icon.svg', { responseType: 'text' }).subscribe(svgData => {
      const svgEl = this.document.createElement('div');
      svgEl.className = 'loader-spin';
      svgEl.innerHTML = svgData;
      this.loaderEl = svgEl;
    });
  }
}
