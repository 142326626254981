<div class="tab-list-with-status-project-wrapper" [attr.isView]="selectedTab === 'Builder' ? 'true' : 'false'">
  <div class="tab-list-wrapper">
    <ul class="tab-list" #scrollContainer >
      <li class="tab-list__item" id="0" #tabItem (click)="scrollToTab(0)">
        <a class="tab-list__link" id="viewTab" [routerLink]="['/system/company/', companyId, 'projects', project?.id, 'builder']" routerLinkActive="active-tab">Builder</a>
      </li>
      <li class="tab-list__item" id="1" #tabItem (click)="scrollToTab(1)">
        <a class="tab-list__link" id="areasTab" [classList]="areaId ? ['active-tab tab-list__link'] : ['tab-list__link']" [routerLink]="['/system/company/', companyId, 'projects', project?.id, 'areas']" routerLinkActive="active-tab">Areas</a>
      </li>
      <li class="tab-list__item" id="2" #tabItem (click)="scrollToTab(2)">
        <a class="tab-list__link" id="progressTab" [routerLink]="['/system/company/', companyId, 'projects', project?.id, 'progress']" routerLinkActive="active-tab" >Project Progress</a>
      </li>
      <li class="tab-list__item" id="3" #tabItem (click)="scrollToTab(3)">
        <a class="tab-list__link" id="tasksTab" [routerLink]="['/system/company/', companyId, 'projects', project?.id, 'tasks']" routerLinkActive="active-tab">Tasks</a>
      </li>
      <li class="tab-list__item" id="4" #tabItem (click)="scrollToTab(4)">
        <a class="tab-list__link" id='activityTab' [routerLink]="['/system/company/', companyId, 'projects', project?.id, 'activity']"  routerLinkActive="active-tab">Activity</a>
      </li>
      <li class="tab-list__item" id="5" #tabItem (click)="scrollToTab(5)">
        <a class="tab-list__link" id='documentsTab' [routerLink]="['/system/company/', companyId, 'projects', project?.id, 'documents']"  routerLinkActive="active-tab">Documents</a>
      </li>
      <li class="tab-list__item" id="6" #tabItem (click)="scrollToTab(6)">
        <a class="tab-list__link" id='drawingsTab' [routerLink]="['/system/company/', companyId, 'projects', project?.id, 'drawings']"  routerLinkActive="active-tab">Drawings</a>
      </li>
      <li class="tab-list__item" id="7" #tabItem (click)="scrollToTab(7)">
        <a class="tab-list__link" id='materialsTab' [routerLink]="['/system/company/', companyId, 'projects', project?.id, 'materials']"  routerLinkActive="active-tab">Materials</a>
      </li>

      <li class="tab-list__item" id="8" #tabItem (click)="scrollToTab(8)">
        <a class="tab-list__link" id='usersTab' [routerLink]="['/system/company/', companyId, 'projects', project?.id, 'users']"  routerLinkActive="active-tab">Users</a>
      </li>
      <li class="tab-list__item" id="9" #tabItem (click)="scrollToTab(9)">
        <a class="tab-list__link" id="settingsTab" [routerLink]="['/system/company/', companyId, 'projects', project?.id, 'settings']" routerLinkActive="active-tab">Settings</a>
      </li>
    </ul>
  </div>


  <div class="project-status-wrapper" [attr.isActivity]="selectedTab === 'Activity'" *ngIf="project?.id">
    <div class="status-bar-with-percent-wrapper">
      <p class="project-bar-title">Project Progress</p>
      <div class="status-bar-colored" [attr.status]="project.status" [style.background]="'linear-gradient(to right, #FF4E00 0%, #FF4E00 ' + project.status + '%, #F0F2F6 ' + project.status + '%), #F0F2F6 100%'"></div>
      <span>{{project.status}} % </span>
    </div>
  </div>
</div>
