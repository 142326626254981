import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {areaWeightByEnum, modalAnimation} from "../../../../../../../config/constants";
import {originalOrder} from "../../../../../../../_util/utils";
import {SelectedArea} from "../../../types";
import {AreaWeight} from "../../../../../../../_models/area";

@Component({
  selector: 'app-area-weight-selector',
  templateUrl: './area-weight-selector.component.html',
  styleUrls: ['./area-weight-selector.component.scss'],
  animations: [modalAnimation]
})
export class AreaWeightSelectorComponent {
  private _selectedArea: SelectedArea
  @Output() changeAreaWeight: EventEmitter<any> = new EventEmitter<any>()
  @Input() isLoadingWeight = false;
  @Input() areaWeight = AreaWeight.MEDIUM
  @Input() withoutLabel = false;
  @Input() areasListRef: ElementRef;
  @Input() areasListLength: number;
  @Input() isAreaDetailsModal = false;

  @Input()
  set selectedArea (value: SelectedArea) {
    if(value) this._selectedArea = value
  }

  get selectedArea() {
    return this._selectedArea
  }


  transformTheFirstLetter(value: string) {
    if(value === 'NO_WEIGHT') return 'No Weight';
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

    protected readonly areaWeightByEnum = areaWeightByEnum;
    protected readonly originalOrder = originalOrder;
}
