import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {originalOrder} from "../../../../../_util/utils";

@Component({
  selector: 'app-simple-selector',
  templateUrl: './simple-selector.component.html',
  styleUrls: ['./simple-selector.component.scss']
})
export class SimpleSelectorComponent{
  @Input()
  set optionsMap(value: Map<number, string>) {
    if(value) {
      this._optionsMap = new Map(value);
      this._optionsMap.forEach((value, key) => this.optionsFromKeysList.push(value))
    }
  }
  get optionsMap() {
    return this._optionsMap
  }
  @Input()
  set selectedOption(value: number) {
    this._selectedOption = value
  }

  get selectedOption() {
    return this._selectedOption;
  }
  @Output() changeEl: EventEmitter<any> = new EventEmitter<any>()
  @Output() close: EventEmitter<any> = new EventEmitter<any>()

  constructor() {
  }


  private _optionsMap = new Map<number, string>()
  private _selectedOption: number;
  optionsFromKeysList: string[] = [];

  closeSelect() {
    this.close.emit()
  }

  protected readonly originalOrder = originalOrder;
}
