<div class="history-container">
  <p *ngIf="history.length === 0" class="no-changes-placeholder">There have been no status changes yet</p>
  <ul *ngIf="history.length > 0" class="history-items">
    <li *ngFor="let item of history">
      <div class="top-line">
        <div class="initiator" [attr.isSystemChange]="!item.initiator">
          <p class="initiator" *ngIf="item.initiator">{{item.initiator.id === (globalStore.currentUser$ | async).id ? 'You' : item.initiator.name}}</p>
          <span *ngIf="item.initiator">changed
            <ng-container [ngSwitch]="item.type">
              <ng-container *ngSwitchCase="'STATUS_PROGRESS'">status progress</ng-container>
              <ng-container *ngSwitchCase="'STATUS'">status</ng-container>
              <ng-container *ngSwitchCase="'WEIGHT'">weight</ng-container>
            </ng-container>
          </span>
          <span *ngIf="!item.initiator">Automatic status change by removing the old one</span>
        </div>
        <p class="timestamp">{{item.timestamp | date: 'MMM d, y h:mm a'}}</p>
      </div>
      <div class="changes">
        <ng-container [ngSwitch]="item.type">
          <ng-container *ngSwitchCase="'STATUS_PROGRESS'">
            <p class="status-progress-text-wrapper">
              <span class="innerHtmlText" [innerHTML]="item.safeHtmlOldValue"></span>
              %
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="'STATUS'">
            <p class="innerHtmlText" [innerHTML]="item.safeHtmlOldValue"></p>
          </ng-container>
          <ng-container *ngSwitchCase="'WEIGHT'">
            <p class="weight-text">
              <svg class="area-weight-icon">
                <use spriteVersion [iconName]="'area-flag-weight-' + item.oldValue.toLowerCase()"></use>
              </svg>
              {{item.oldValue | titlecase}}
            </p>
          </ng-container>
        </ng-container>
        <p>to</p>
        <ng-container [ngSwitch]="item.type">
          <ng-container *ngSwitchCase="'STATUS_PROGRESS'">
            <p class="status-progress-text-wrapper">
              <span class="innerHtmlText" [innerHTML]="item.safeHtmlNewValue"></span>
              %
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="'STATUS'">
            <p class="innerHtmlText" [innerHTML]="item.safeHtmlNewValue"></p>
          </ng-container>
          <ng-container *ngSwitchCase="'WEIGHT'">
            <p class="weight-text">
              <svg class="area-weight-icon">
                <use spriteVersion [iconName]="'area-flag-weight-' + item.newValue.toLowerCase()"></use>
              </svg>
              {{item.newValue | titlecase}}
            </p>
          </ng-container>
        </ng-container>
      </div>
    </li>
  </ul>
</div>
