import {Component, QueryList, ViewChildren} from '@angular/core';
import {NgSelectComponent} from "@ng-select/ng-select";
import {Page} from "../../../../../_models/page";
import {UserRole} from "../../../../../_models/user";
import {filter, Subject, switchMap, takeUntil, tap} from "rxjs";
import {UsersService} from "../../../../../_services/users.service";
import {NotificationService} from "../../../../../_services/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalStore} from "../../../../../global.store";
import {originalOrder} from "../../../../../_util/utils";
import {areaWeightByEnum, SortType} from "../../../../../config/constants";
import {AreasService} from "../../../../../_services/areas.service";
import {Floor} from "../../../../../_models/floor";
import {ProjectCustomColors, ProjectCustomStatuses} from "../../../../../_models/project";
import {Area, AreaListItem, AreaWeight} from "../../../../../_models/area";
import {FloorsService} from "../../../../../_services/floors.service";
import {ProjectAreaStatusesService} from "../../../../../_services/project-area-statuses.service";

@Component({
  selector: 'app-areas-list',
  templateUrl: './areas-list.component.html',
  styleUrls: ['./areas-list.component.scss'],
})
export class AreasListComponent {
  @ViewChildren(NgSelectComponent) ngSelectComponent: QueryList<NgSelectComponent>;

  sorting: any = {};
  query: string;
  hideCompleted = false;
  pageNumber = 0;
  pageSizeOnDesktop = 20;
  pageSizeOnMobile: number;
  isLoading = false;
  floorsShortList: Floor[] = [];
  projectStatusesList: ProjectCustomStatuses[] = [];

  areasPage: Page<AreaListItem>;


  currentUser = this.globalStore.currentUser$;

  selectedStatus: ProjectCustomStatuses;
  selectedFloor: Floor;


  isMobile= this.globalStore.isMobile$;
  isIOS = this.globalStore.isIOS$;

  companyId: number;
  projectId: number;

  private readonly destroy$ = new Subject<void>()

  constructor(private areasService: AreasService,
              private userService: UsersService,
              private floorService: FloorsService,
              private projectAreaStatusesService: ProjectAreaStatusesService,
              private notifService: NotificationService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              readonly globalStore: GlobalStore) {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);

    this.globalStore.projectId$.pipe(
      takeUntil(this.destroy$),
      filter((projectId) => !!projectId),
      switchMap((projectId) => {
        this.projectId = projectId;
        return this.userService.getConfirmedEmployeesOnProjectShort(this.projectId)
      })
    ).subscribe(() => {
      this.getFloorsShortList()
      this.getProjectStatusesList();
      this.getAreasList()
    });
  }

  getAreasList() {
    this.isLoading = true;
    let sortingField;
    let sortingDirection;

    if (Object.keys(this.sorting).length > 0) {
      sortingField = Object.keys(this.sorting)[0];
      sortingDirection = this.sorting[Object.keys(this.sorting)[0]];
    }

    this.areasService.getAreasListByParams(this.projectId, this.selectedFloor?.id, this.selectedStatus?.id, this.query, sortingField, sortingDirection, this.pageNumber, this.pageSizeOnDesktop).subscribe(data => {
      if (data.totalPages > 0 && this.pageNumber + 1 > data.totalPages) {
        this.pageNumber = data.totalPages - 1;
        this.getAreasList();
      } else {
        this.areasPage = data;
        this.isLoading = false;
      }
    });
  }

  getFloorsShortList() {
    this.floorService.getFloorsShortList(this.projectId).subscribe(data => this.floorsShortList = data)
  }

  getProjectStatusesList() {
    this.projectAreaStatusesService.getProjectAreaStatuses(this.projectId).subscribe(data => this.projectStatusesList = data)
  }

  addColorToSelectValue(statusSelected: ProjectCustomStatuses): any {
    if (statusSelected.orderNumber < 0) {
      if (statusSelected.orderNumber !== -3) return 'white'
      else return 'black'
    }
    if (statusSelected.color === ProjectCustomColors.YELLOW || statusSelected.color === ProjectCustomColors.GREY || statusSelected.color === ProjectCustomColors.MINT) {
      return "black"
    } else return "white"
  }

  changeShowNumber(newNumber: number) {
    this.pageSizeOnDesktop = newNumber;
    this.pageNumber = 0;
    this.pageSizeOnMobile = newNumber;
    this.getAreasList();
  }

  changeCurrentPage(newPage: number) {
    this.pageNumber = newPage;
    this.getAreasList();
  }

  getFiltersActiveAmount() {
    let result = 0;
    if (this.selectedFloor) result++;
    if (this.selectedStatus) result++;

    return result;
  }

  clearFilters() {
    this.selectedFloor = null;
    this.selectedStatus = null;
    this.pageNumber = 0;
    this.getAreasList()
  }

  onSortBy(sortBy: string) {
    if (this.sorting[sortBy] !== undefined) this.sorting[sortBy] = this.sorting[sortBy] === SortType.ASC ? SortType.DESC : SortType.ASC;
    else {
      this.sorting = {};
      this.sorting[sortBy] = SortType.DESC;
    }
    this.getAreasList();
  }

  isNoFilteringEnabled() {
    return this.getFiltersActiveAmount() === 0 && !this.hideCompleted && (!this.query || this.query.length === 0);
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly areaWeightByEnum = areaWeightByEnum;
  protected readonly SortType = SortType;
  protected readonly UserRole = UserRole;
  protected readonly originalOrder = originalOrder;
  protected readonly AreaWeight = AreaWeight;
  protected readonly event = event;
}
