import {
  AfterContentInit,
  Component, ElementRef, EventEmitter,
  forwardRef, Input, Output,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {NgxMaskDirective} from "ngx-mask";

@Component({
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDatapickerComponent),
      multi: true
    }
  ]
})
export class CustomDatapickerComponent implements ControlValueAccessor, AfterContentInit {
  @ViewChild(NgxMaskDirective, {static: true}) mask: NgxMaskDirective;
  @Input() id: string;
  @Input() placeholder: string;
  @Input() isClearable: boolean = false;

  @Output() clearDate: EventEmitter<any> = new EventEmitter<any>()

  pickerOpened: boolean = false;

  date: Date = new Date();
  currentMonth: number;
  currentYear: number;
  currentDay: number;
  daysInMonthArr: Array<number> = [];
  selectedDateInfo: Object = {}

  dateInvalid = false;
  calendarOnTop = false;
  months: Array<string> = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  result: string;

  onChange: any = () => {
  }
  onTouch: any = () => {
  }

  constructor(private el: ElementRef, private datePipe: DatePipe) {
    this.currentMonth = this.date.getMonth() + 1;
    this.currentYear = this.date.getFullYear();
    this.updateCalendar();
  }

  ngAfterContentInit() {
    const element = this.el.nativeElement;
    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    if(!this.isClearable) this.calendarOnTop = rect.top > (viewportHeight - rect.bottom);
  }

  isThisDateSelected(day: number) {
    if(day < 10 && this.currentMonth >= 10) return this.result === `${this.currentYear}-${this.currentMonth}-0${day}`
    else if(day < 10 && this.currentMonth < 10) return this.result === `${this.currentYear}-0${this.currentMonth}-0${day}`
    else if(day >= 10 && this.currentMonth < 10) return this.result === `${this.currentYear}-0${this.currentMonth}-${day}`
    else return this.result === `${this.currentYear}-${this.currentMonth}-${day}`;
  }

  onDayClicked(day: number) {
    this.selectedDateInfo = {
      day,
      month: this.currentMonth,
      year: this.currentYear
    }
    // this.result = new Date(this.currentYear, this.currentMonth - 1, day).toLocaleString('uk-UA', this.options)
    if(day < 10 && this.currentMonth >= 10) this.result = `${this.currentYear}-${this.currentMonth}-0${day}`
    else if(day < 10 && this.currentMonth < 10) this.result = `${this.currentYear}-0${this.currentMonth}-0${day}`
    else if(day >= 10 && this.currentMonth < 10) this.result = `${this.currentYear}-0${this.currentMonth}-${day}`
    else this.result = `${this.currentYear}-${this.currentMonth}-${day}`
    this.onChange(this.result);
    this.pickerOpened = false;
  }

  getFormattedResult(): string {
    // if(isNaN(new Date(this.result).getTime())) return ''
    const [year, month, day] = this.result.split('-')
    return `${month}.${day}.${year}`;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  writeValue(value: number): void {
    if (!value) return;

    this.onInputChange(value);
    if (!this.dateInvalid) this.mask.writeValue(this.datePipe.transform(value, 'MM.dd.YY'));
  }

  updateCalendar() {
    this.getDaysInMonth(this.currentYear, this.currentMonth);
    this.addDaysPreviousMonthToCurrent(this.currentYear, this.currentMonth)
    this.addDaysNextMonthToCurrent(this.currentYear, this.currentMonth)
  }

  addDaysPreviousMonthToCurrent(year: number, month: number) {
    let firstDateOfMonth = new Date(year, month - 1, 1);
    const dayOfWeek = firstDateOfMonth.getDay();

    if (dayOfWeek === 0) {
      return
    }

    let prevMonth = month - 1;

    const amountDaysPrevious = this.getAmountDaysInMonth(year, prevMonth);
    const difference = amountDaysPrevious - dayOfWeek;

    for (let day = amountDaysPrevious; day > difference; day--) {
      this.daysInMonthArr.unshift(day);
    }

  }

  addDaysNextMonthToCurrent(year: number, month: number) {
    const amountDaysInCurrentMonth = this.getAmountDaysInMonth(year, month);
    let lastDateOfMonth = new Date(year, month - 1, amountDaysInCurrentMonth);
    const dayOfWeek = lastDateOfMonth.getDay();

    if (dayOfWeek === 6 && this.daysInMonthArr.length === 42) {
      return
    }

    const leftDaysOfWeek = 6 - dayOfWeek;

    for (let day = 1; day <= leftDaysOfWeek + 7; day++) {
      if (this.daysInMonthArr.length === 42) {
        return;
      }
      this.daysInMonthArr.push(day);
    }

  }

  getAmountDaysInMonth(year: number, month: number) {
    return new Date(year, month, 0).getDate();
  }

  //give month start from 1
  getDaysInMonth(year: number, month: number) {
    this.daysInMonthArr = [];
    const days = new Date(year, month, 0).getDate();
    for (let day = 1; day <= days; day++) {
      this.daysInMonthArr.push(day);
    }
  }


  goToPreviousMonth() {
    this.currentMonth = this.currentMonth - 1;
    if (this.currentMonth === 0) {
      this.currentMonth = 12;
      this.currentYear = this.currentYear - 1;
    }

    this.updateCalendar();
  }

  goToNextMonth() {
    this.currentMonth = this.currentMonth + 1;
    if (this.currentMonth === 13) {
      this.currentMonth = 1;
      this.currentYear = this.currentYear + 1;
    }

    this.updateCalendar();
  }

  onInputChange(value: any) {
    this.dateInvalid = false;

    const inputDate = new Date(value);
    if (inputDate.toString() === "NaN") this.dateInvalid = true;
    else {
      this.currentYear = inputDate.getFullYear();
      this.currentMonth = inputDate.getMonth() + 1;
      this.currentDay = inputDate.getDate()
      if(this.currentDay < 10 && this.currentMonth >= 10) this.result = `${this.currentYear}-${this.currentMonth}-0${this.currentDay}`
      else if(this.currentDay < 10 && this.currentMonth < 10) this.result = `${this.currentYear}-0${this.currentMonth}-0${this.currentDay}`
      else if(this.currentDay >= 10 && this.currentMonth < 10) this.result = `${this.currentYear}-0${this.currentMonth}-${this.currentDay}`
      else this.result = `${this.currentYear}-${this.currentMonth}-${this.currentDay}`
      if(isNaN(new Date(this.result).getTime())) {
        this.result = '';
        this.onChange(this.result)
        return
      }
      this.onChange(this.result);
      this.updateCalendar()
    }
  }
}
