import {Component} from '@angular/core';
import {UserRole} from "../../../../_models/user";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Project} from "../../../../_models/project";
import {GlobalStore} from "../../../../global.store";
import {filter, Subject, switchMap, takeUntil} from "rxjs";

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent {
  private readonly destroy$ = new Subject<void>();
  selectedTab: string;
  companyName: string;
  companyId: number;
  project: Project;
  constructor(readonly globalStore: GlobalStore) {

    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
    this.globalStore.companyName$.pipe(takeUntil(this.destroy$)).subscribe((companyName) => this.companyName = companyName);
    this.globalStore.project$.pipe(takeUntil(this.destroy$)).subscribe((project) => this.project = project);
  }

  changeSelectedTab(selectedTab: string) {
    this.selectedTab = selectedTab
  }
  protected readonly UserRole = UserRole;
}
