import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AreaStatusDefaultOrder, AreaWeight} from "../../../../../../../_models/area";
import {UserRole} from "../../../../../../../_models/user";
import {AreasService} from "../../../../../../../_services/areas.service";
import {NotificationService} from "../../../../../../../_services/notification.service";
import {GlobalStore} from "../../../../../../../global.store";
import {Subject} from "rxjs";
import {SelectedArea} from "../../../types";
import {ProjectCustomColors, ProjectCustomStatuses} from "../../../../../../../_models/project";

@Component({
  selector: 'app-area-details-section',
  templateUrl: './area-details-section.component.html',
  styleUrls: ['./area-details-section.component.scss']
})
export class AreaDetailsSectionComponent {
  @Output() updated: EventEmitter<SelectedArea> = new EventEmitter<SelectedArea>();
  @Output() updateHistory: EventEmitter<SelectedArea> = new EventEmitter<SelectedArea>();
  @Input() isAreaDetailsPage = false;
  @Input()
  set selectedArea(value: SelectedArea) {
    if(value) {
      this._selectedArea = value;
      this.statusProgressDump = value.area.statusProgress;
    }
  }
  @Input() projectStatuses: ProjectCustomStatuses[] = [];

  get selectedArea(): SelectedArea {
    return this._selectedArea;
  }

  private _selectedArea: SelectedArea;
  private readonly destroy$ = new Subject<void>()
  isLoadingStatus = false;
  isLoadingWeight = false;
  statusProgressDump = 0;
  startChangeStatusProgress = false;
  isLoadingStatusProgress = false;
  isChangeStatusAreaLoading = false

  constructor(private areasService: AreasService,
              private notifService: NotificationService,
              readonly globalStore: GlobalStore) {
  }


  addBackgroundToStatusItem(status: ProjectCustomStatuses) {
    if (status.orderNumber === -3) return null
    else return status.color
  }

  addColorToSelectValue(statusSelected: ProjectCustomStatuses): any {
    if (statusSelected.orderNumber < 0) {
      if (statusSelected.orderNumber !== -3) return 'white'
      else return 'black'
    }
    if (statusSelected.color === ProjectCustomColors.YELLOW || statusSelected.color === ProjectCustomColors.GREY || statusSelected.color === ProjectCustomColors.MINT) {
      return "black"
    } else return "white"
  }

  changeAreaStatus(statusId: number) {
    this._selectedArea.area.status = this.findSelectedStatus(statusId);
    this._selectedArea.area.status.inUse = false;
    this.isLoadingStatus = true;

    this.isChangeStatusAreaLoading = true
    this.areasService.updateAreaStatusById(this._selectedArea.area.id, statusId).subscribe(() => {
      this.startChangeStatusProgress = false;
      this.findSelectedStatus(statusId).inUse = true;
      this.notifService.successNotification('Changes have been saved');

      switch (this._selectedArea.area.status.orderNumber) {
        case AreaStatusDefaultOrder.TO_DO: {
          this._selectedArea.area.statusProgress = 0;
          this.statusProgressDump = this._selectedArea.area.statusProgress;
          break
        }

        case AreaStatusDefaultOrder.DONE: {
          this._selectedArea.area.statusProgress = 100;
          this.statusProgressDump = this._selectedArea.area.statusProgress;
          break
        }
      }
      this._selectedArea.area.attachmentsAmount = this._selectedArea.area.files.length
      this._selectedArea.area.commentsAmount = this._selectedArea.area.comments.length
      this.isChangeStatusAreaLoading = false
      this.updated.emit(this._selectedArea);
      this.isLoadingStatus = false;
      this.updateHistory.emit();
    })
  }

  updateAreaStatusProgress() {
    this._selectedArea.area.statusProgress = this.statusProgressDump;
    this.isLoadingStatusProgress = true;
    this.areasService.updateAreaStatusProgressById(this._selectedArea.area.id, this._selectedArea.area.statusProgress).subscribe(() => {
      this.startChangeStatusProgress = false;
      this._selectedArea.area.attachmentsAmount = this._selectedArea.area.files.length
      this._selectedArea.area.commentsAmount = this._selectedArea.area.comments.length
      this.updated.emit(this._selectedArea);
      this.notifService.successNotification('Changes have been saved');
      this.updateHistory.emit();
      this.isLoadingStatusProgress = false;
    })
  }

  calculateProgressSquareAttrs(el: HTMLElement, bar: number) {
    let backgroundColor;
    let opacity;
    if (this.statusProgressDump < bar) {
      backgroundColor = '#E5E8ED';
      opacity = 1;
    } else {
      backgroundColor = '#37A702';
      opacity = Math.max(this.statusProgressDump, 10) / 100;
    }
    el.style.backgroundColor = backgroundColor;
    el.style.opacity = String(opacity);
  }

  changeAreaWeight(areaWeight: AreaWeight) {
    this._selectedArea.area.areaWeight = areaWeight;
    this.isLoadingWeight = true;
    this.isChangeStatusAreaLoading = true;
    this.areasService.updateAreaWeightById(this._selectedArea.area.id, this._selectedArea.area.areaWeight).subscribe(() => {
      this.notifService.successNotification('Changes have been saved');
      this.updateHistory.emit();
      this._selectedArea.area.attachmentsAmount = this._selectedArea.area.files.length
      this._selectedArea.area.commentsAmount = this._selectedArea.area.comments.length
      this.updated.emit(this._selectedArea);
      this.isChangeStatusAreaLoading = false;
      this.isLoadingWeight = false;
    })
  }

  findSelectedStatus(statusId: number) {
    return this.projectStatuses.find(status => status.id === statusId)
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

    protected readonly AreaStatusDefaultOrder = AreaStatusDefaultOrder;
    protected readonly UserRole = UserRole;
}
