<app-floors-swiper [roomsAmount]="roomsAmount"></app-floors-swiper>
<app-view-area-status [statusesInUseAmount]="projectViewStore.statistics$ | async"
                      [projectStatuses]="projectViewStore.projectStatuses$ | async"
                      (updateStatuses)="projectViewStore.loadProjectStatuses()">
</app-view-area-status>
<div class="slider-wrapper" *ngIf="!(projectViewStore.floorsInLoading$ | async) && currentFloor">
  <ng-scrollbar class="remove-overflow-on-tablet scroll-custom-units" #ngScrollbar [attr.isLotOfProjectStatuses]="(projectViewStore.projectStatuses$ | async).length > 7" [attr.isIOS]="(globalStore.isIOS$ | async)" [autoHeightDisabled]="true" [track]="'horizontal'">
    <div class="swiper unit-swiper" [attr.isLoading]="!currentFloor">
      <ul class="swiper-wrapper unit-list" #unitsList cdkDropList cdkDropListOrientation="horizontal" id="unitListId"
          [cdkDropListAutoScrollStep]="40"
          [cdkDropListDisabled]="projectViewStore.isCdkDragDisabled$ | async"
          (cdkDropListDropped)="dropUnits($event)">
        <app-unit-block cdkDrag
                        [cdkDragDisabled]="projectViewStore.isCdkDragDisabled$ | async"
                        cdkDragPreviewContainer="parent" [id]="'unit-' + unit.id"
                        *ngFor="let unit of currentFloor.units"
                        [unit]="unit"
                        [cdkDragData]="unit"
                        (openDeletingPopup)="openDeletingPopup($event)"
                        [isNotDesktop]="isNotDesktop"
                        [currentFloor]="currentFloor"
                        [isCdkDragDisabled]="projectViewStore.isCdkDragDisabled$ | async"
                        [roomTemplatesShortList]="projectViewStore.roomTemplatesShortList$ | async">
        </app-unit-block>
        <li class="swiper-slide add-unit-wrapper" *ngIf="(globalStore.currentUser$ | async).role !== UserRole.ROLE_EMPLOYEE">
          <div
            (appOutsideClick)="isAddUnitMenuOpen = false; isAddUnitFormOpen = false; projectViewStore.updateIsCdkDragDisabled(false)"
            [enabled]="isAddUnitMenuOpen">
            <button #addUnitBtn type="button" class="add-unit-btn" id="addUnit"
                    (click)="projectViewStore.updateIsCdkDragDisabled(true); floorDuplicateListAsUnits.length !== 0 ? isAddUnitMenuOpen = !isAddUnitMenuOpen : openCreateUnitForm($event)">
              Add Unit
            </button>

            <div class="add-floor-menu add-unit-menu"
                  *ngIf="isAddUnitMenuOpen"
                 [@modalAnimation]>
              <button type="button" id="createNewUnit" class="create-new-unit-btn" (click)="openCreateUnitForm($event)"
                      [style.padding-left]="'12px'">
                <svg class="create-icon">
                  <use spriteVersion iconName="create-icon"></use>
                </svg>
                Create New Unit
              </button>
              <p class="duplicate-text" style="padding-left: 12px">Duplicate</p>
              <div class="duplicate-units-lists-wrapper">
                <ul class="duplicate-units-variants-floors-list" #floorsDuplicateUnitsList>
                  <li class="duplicate-units-variants-floors-list__item"
                      *ngFor="let floor of (projectViewStore.floorDuplicateMenuList$ | async); let idx = index"
                      (mouseout)="unitsDuplicateListTopPosition = null; mouseOutFloor($event)"
                      (mouseover)="setCoordinateUnitsList($event, floor)" [id]="idx">
                          <span
                            [style.background-color]="isFloorDuplicateHover && floorDuplicateSelected.name === floor.name ? '#F0F2F5' : ''">
                            {{ floor.name }}
                            <svg class="arrow-duplicate" *ngIf="floor.units.length !== 0">
                              <use spriteVersion iconName="arrow-right-duplicate"></use>
                            </svg>
                          </span>

                    <div class="duplicate-units-variants-list__wrapper"
                         [style.top]="unitsDuplicateListTopPosition + 'px'">
                      <ul class="duplicate-units-variants-list"
                          *ngIf="isFloorDuplicateHover && floorDuplicateSelected.name === floor.name"
                          #unitsDuplicateList
                          (mouseleave)="isFloorDuplicateHover = null; floorDuplicateSelected = null">
                        <li *ngFor="let unit of floorDuplicateSelected?.units" class="duplicate-units-variants__item"
                            [id]="unit?.id"
                            (click)="duplicateUnit(unit.id); isAddUnitMenuOpen = false">{{ unit?.name }}
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <form class="form-create-unit-instead-btn"
                firstInputAutofocus
                stopClickPropagation
                #ngFormAddUnit="ngForm"
                *ngIf="isAddUnitFormOpen"
                (appOutsideClick)="isAddUnitFormOpen = false"
                [enabled]="isAddUnitFormOpen"
                (ngSubmit)="createNewUnit(crateUnitCloseBtn)"
                [formGroup]="createUnitForm">
            <input type="text" class="input-create-item" formControlName="name" id="createUnitInputDesk"
                   formInput="FLOOR_UNIT_NAME">
            <p class="small-error-text-field" id="unitNameErrorDesk"
               *ngIf="ngFormAddUnit.submitted && createUnitForm.controls['name'].invalid">{{ createUnitForm.controls['name'].getError('error') }}</p>
            <div class="form-create-btn-wrapper">
              <button type="submit" id="createUnitBtnDesk" class="create-item-btn">Add Unit</button>
              <button type="reset" id="canselBtnCreateUnitDesk" #crateUnitCloseBtn class="cansel-create-btn"
                      (click)="closeUnitForm()">
                <svg class="close-icon-form">
                  <use spriteVersion iconName="close-icon"></use>
                </svg>
              </button>
            </div>
          </form>

        </li>
        <li class="swiper-slide unit-without-bg" *ngIf="currentFloor.units.length < 1"
            [style.height]="unitsList.scrollHeight - 20 + 'px'"></li>
        <li class="swiper-slide unit-without-bg" *ngIf="currentFloor.units.length < 2"
            [style.height]="unitsList.scrollHeight - 20 + 'px'"></li>
        <li class="swiper-slide unit-without-bg" [style.height]="unitsList.scrollHeight - 20 + 'px'"></li>
      </ul>
    </div>
  </ng-scrollbar>
</div>

<div class="slider-wrapper" *ngIf="(projectViewStore.floorsInLoading$ | async)">
  <div class="swiper unit-swiper">
    <app-loader-units></app-loader-units>
  </div>
</div>
<app-room-form *ngIf="(projectViewStore.popupOpened$ | async) === PopupOpened.ROOM_CREATE" [popupOpened]='PopupOpened.ROOM_CREATE'
               (created)="roomCreated($event); globalStore.loadProjectProgress()"
               [selectedRoom]="projectViewStore.selectedObject$ | async"
               (close)="editPopupClosed()"></app-room-form>
<app-room-form *ngIf="(projectViewStore.popupOpened$ | async) === PopupOpened.CREATE_EDIT_ROOM_CREATED_BASED_ON_TEMPLATE" [popupOpened]="PopupOpened.CREATE_EDIT_ROOM_CREATED_BASED_ON_TEMPLATE"
               (updated)="roomUpdated(); globalStore.loadProjectProgress()"
               (created)="roomCreated($event); globalStore.loadProjectProgress()"
               (close)="editPopupClosed()"
               [selectedRoom]="projectViewStore.selectedObject$ | async"></app-room-form>
<app-room-form *ngIf="(projectViewStore.popupOpened$ | async) === PopupOpened.ROOM_EDIT" [popupOpened]='PopupOpened.ROOM_EDIT'
               (updated)="roomUpdated(); globalStore.loadProjectProgress()"
               (close)="editPopupClosed(); globalStore.loadProjectProgress()"
               [selectedRoom]="projectViewStore.selectedObject$ | async"></app-room-form>
<app-room-form *ngIf="(projectViewStore.popupOpened$ | async) === PopupOpened.TEMPLATE_EDIT" [popupOpened]='PopupOpened.TEMPLATE_EDIT'
               (updated)="roomUpdated(); globalStore.loadProjectProgress()"
               (close)="editPopupClosed(); globalStore.loadProjectProgress()"
               [selectedRoom]="projectViewStore.selectedObject$ | async"></app-room-form>
<app-area-form *ngIf="(projectViewStore.popupOpened$ | async) === PopupOpened.AREA_VIEW"
               (close)="editPopupClosed(); this.router.navigate([], { queryParams: {} });"
               [projectStatuses]="projectViewStore.projectStatuses$ | async"
               (updated)="updateArea($event)"
               (removeAreaFile)="removeAreaFile($event)"
               [selectedArea]="projectViewStore.selectedObject$ | async"></app-area-form>


<div class="backdrop manual" [attr.show]="deletingPopup !== 'none'">
  <div [enabled]="deletingPopup !== 'none'"
       (appOutsideClick)="deletingPopup = 'none'; projectViewStore.updateIsCdkDragDisabled(false)" class="modal-narrow">
    <button type="button" id="btnCloseModal" class="close-modal-btn close-modal-deleting"
            (click)="deletingPopup = 'none'; projectViewStore.updateIsCdkDragDisabled(false)">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>

    <div class="confirmDeleting-wrapper">
      <ng-container *ngIf="deletingPopup === 'unit'">
        <p class="confirmDeleting-title">Delete Unit “{{ unitToDelete.name }}”?</p>
        <p class="confirmDeleting-text">Delete an existing unit in the system. If you delete this unit, it will also
          delete:</p>
        <ul class="confirmDeleting-list">
          <li> - All rooms of this unit</li>
        </ul>
      </ng-container>
      <ng-container *ngIf="deletingPopup === 'template'">
        <p class="confirmDeleting-title">Delete Template “{{ templateToDelete.name }}”?</p>
        <p class="confirmDeleting-text">If you delete this template, you will automatically delete all rooms based on
          this template on all units and floors</p>
      </ng-container>
    </div>

    <div class="form-btn-wrapper">
      <button type="button" id="btnCancel" class="btn-cancel"
              (click)="deletingPopup = 'none'; projectViewStore.updateIsCdkDragDisabled(false)">Cancel
      </button>
      <button type="submit" id="btnDeleteProject" class="btn-delete" [disabled]="deletingBtnDisabled"
              (click)="deletingPopup === 'template' ? deleteSelectedTemplate() : deleteSelectedUnit(); projectViewStore.updateIsCdkDragDisabled(false)">
        <svg class="btn-delete__icon">
          <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        Delete {{ deletingPopup === 'template' ? 'Template' : 'Unit' }}
      </button>
    </div>
  </div>
</div>
