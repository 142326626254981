import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';

import {AdminRoutingModule} from './admin-routing.module';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {SystemHomeComponent} from "./components/system-home/system-home.component";
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {CompaniesComponent} from './components/companies/companies.component';
import {ProjectsComponent} from './components/projects/projects.component';
import {UsersComponent} from './components/users/users.component';
import {SubHeaderComponent} from './components/sub-header/sub-header.component';
import {FilterFieldsComponent} from './components/filter-fields/filter-fields.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxSelectModule} from "ngx-select-ex";
import {NgSelectModule} from "@ng-select/ng-select";

import {CustomDatapickerComponent} from './components/inputs/custom-datepicker/custom-datapicker.component';
import {NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask} from "ngx-mask";
import {ProjectFormComponent} from './components/projects/project-form/project-form.component';
import {NotificationComponent} from './components/notification/notification.component';
import {CompanyFormComponent} from './components/companies/company-form/company-form.component';
import {InviteUserFormComponent} from './components/users/invite-user-form/invite-user-form.component';
import {ProjectDetailsComponent} from "./components/project-details/project-details.component";
import {OutsideClickDirective} from "../../_directives/outside-click.directive";
import {CompanySettingsComponent} from './components/company-settings/company-settings.component';
import {UserProfileComponent} from './components/user-profile/user-profile.component';
import {SystemErrorPageComponent} from './components/system-error-page/system-error-page.component';
import {RedirectComponent} from './components/redirect/redirect.component';
import {UserProfileFormComponent} from './components/user-profile/user-profile-form/user-profile-form.component';
import {
    ChangePasswordFormComponent
} from './components/user-profile/change-password-form/change-password-form.component';
import {HomeRedirectComponent} from './components/home-redirect/home-redirect.component';
import {ProjectSettingsComponent} from './components/project-details/project-settings/project-settings.component';
import {AlphanumericDirective} from "../../_directives/alphanumeric.directive";
import {AddUsersFormComponent} from './components/users/add-users-form/add-users-form.component';
import {PhonePrettifyPipe} from "../../_pipes/phonePrettifyPipe";
import {TasksComponent} from './components/project-details/tasks/tasks.component';
import {TaskFormComponent} from './components/project-details/tasks/task-form/task-form.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {PaginationComponent} from './components/pagination/pagination.component';
import {FileDropAreaDirective} from "../../_directives/file-drop-area.directive";
import {FileSizePrettifyPipe} from "../../_pipes/fileSizePrettifyPipe";
import {SliceTextPipe} from "../../_pipes/sliceTextPipe";
import {LoaderDirective} from "../../_directives/loader.directive";
import {FormInputDirective} from "../../_directives/formInput.directive";
import {UrlifyDirective} from "../../_directives/urlify.directive";
import {ProjectViewComponent} from './components/project-details/project-view/project-view.component';
import {SwiperModule} from 'swiper/angular';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {RoomFormComponent} from './components/project-details/project-view/room-form/room-form.component';
import {FloorsSwiperComponent} from './components/project-details/project-view/floors-swiper/floors-swiper.component';
import {UnitBlockComponent} from './components/project-details/project-view/unit-block/unit-block.component';
import {RoomBlockComponent} from './components/project-details/project-view/room-block/room-block.component';
import {StopClickPropagationDirective} from "../../_directives/stopClickPropagation.directive";
import {ProjectProgressComponent} from './components/project-details/project-progress/project-progress.component';
import {ViewAreaStatusComponent} from './components/project-details/view-area-status/view-area-status.component';
import {AreaFormComponent} from './components/project-details/project-progress/area-form/area-form.component';
import {LoaderComponent} from './components/loader/loader.component';
import {RoomNamePipe} from "../../_pipes/roomNamePipe";
import {
    ActivityAreaComponent
} from './components/project-details/project-progress/area-form/activity-area/activity-area.component';
import {QuillModule} from "ngx-quill";
import { HistoryAreaComponent } from './components/project-details/project-progress/area-form/history-area/history-area.component';
import { FilesAreaComponent } from './components/project-details/project-progress/area-form/files-area/files-area.component';
import { AreaEditorComponent } from './components/project-details/project-progress/area-form/activity-area/area-editor/area-editor.component';
import { FiltersProjectProgressComponent } from './components/project-details/project-progress/filters-project-progress/filters-project-progress.component';
import { StatusColorPickerComponent } from './components/project-details/project-progress/status-color-picker/status-color-picker.component';
import {AutoTrimDirective} from "../../_directives/autoTrim.directive";
import {TimePrettifyPipe} from "../../_pipes/timePrettify.pipe";
import {SetLinkToNotification} from "../../_pipes/setLinkToNotificationPipe";
import { NgToggleModule } from 'ng-toggle-button';
import {VisibilityObserverDirective} from "../../_directives/visibilityObserver.directive";
import {DateFormatterPipe} from "../../_pipes/dateFormatterPipe";
import {ScrollToFirstInvalidInputDirective} from "../../_directives/scrollToFirstInvalidInputDirective";
import {VarDirective} from "../../_directives/var.directive";
import {IsLowerThenMiddleOfScreen} from "../../_directives/isLowerThenMiddleOfScreen.directive";
import {IsRighterThenMiddleOfScreenDirective} from "../../_directives/isRighterThenMiddleOfScreen.directive";
import { LoaderProfileComponent } from './components/loader/loader-profile/loader-profile.component';
import { LoaderUnitsComponent } from './components/loader/loader-units/loader-units.component';
import { LoaderProjectProgressComponent } from './components/loader/loader-project-progress/loader-project-progress.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DeletingStatusModalComponent } from './components/project-details/project-progress/status-color-picker/deleting-status-modal/deleting-status-modal.component';
import { DashboardCompaniesTableComponent } from './components/dashboard/dashboard-companies-table/dashboard-companies-table.component';
import { DashboardProjectsTableComponent } from './components/dashboard/dashboard-projects-table/dashboard-projects-table.component';
import { DashboardTasksTableComponent } from './components/dashboard/dashboard-tasks-table/dashboard-tasks-table.component';
import { DashboardActivityTableComponent } from './components/dashboard/dashboard-activity-table/dashboard-activity-table.component';
import { ActivityProjectTableComponent } from './components/project-details/activity-project-table/activity-project-table.component';
import { NestedEllipsisModule } from 'ngx-nested-ellipsis';
import { FloorsListComponent } from './components/project-details/project-progress/floors-list/floors-list.component';
import { UnitsListComponent } from './components/project-details/project-progress/units-list/units-list.component';
import { RoomsListComponent } from './components/project-details/project-progress/rooms-list/rooms-list.component';
import {SpriteVersionDirective} from "../../_directives/spriteVersionDirective";
import {FirstInputAutofocusOnMobileDirective} from "../../_directives/firstInputAutofocusOnMobile.directive";
import {AutoScrollbarDirective} from "../../_directives/auto-scrollbar.directive";
import {EllipsisModule} from "ngx-ellipsis";
import { EmailNotificationSettingsComponent } from './components/sub-header/email-notification-settings/email-notification-settings.component';
import { FloorsSwiperElComponent } from './components/project-details/project-view/floors-swiper/floors-swiper-el/floors-swiper-el.component';
import { ProjectMaterialsComponent } from './components/project-details/project-materials/project-materials.component';
import { ProjectMaterialFormComponent } from './components/project-details/project-materials/project-material-form/project-material-form.component';
import { MaterialCustomSelectorComponent } from './components/project-details/project-materials/project-material-form/material-custom-selector/material-custom-selector.component';
import { MaterialCustomSelectorWithColoredOptionsComponent } from './components/project-details/project-materials/project-material-form/material-custom-selector-with-colored-options/material-custom-selector-with-colored-options.component';
import { AddFilesToFormComponent } from './components/add-files-to-form/add-files-to-form.component';
import {CountriesCodeSelectComponent} from "./components/contries-code-select/countries-code-select.component";
import {IsLowerThenMiddleOfContainerDirective} from "../../_directives/isLowerThenMiddleOfContainer.directive";
import { DesignSettingsComponent } from './components/project-details/project-progress/design-settings/design-settings.component';
import { AreaAddFilesComponent } from './components/project-details/project-progress/area-form/activity-area/area-add-files/area-add-files.component';
import { DocumentsListComponent } from './components/project-details/documents-list/documents-list.component';
import { CustomSelectComponent } from './components/project-details/documents-list/app-custom-select/custom-select.component';
import { DocumentFormComponent } from './components/project-details/documents-list/document-form/document-form.component';
import {DocumentsTypeIconDirective} from "../../_directives/documents-type-icon.directive";
import { DrawingsListComponent } from './components/project-details/drawings-list/drawings-list.component';
import { DrawingFormComponent } from './components/project-details/drawings-list/drawing-form/drawing-form.component';
import {NgxDocViewerModule} from "ngx-doc-viewer";
import { FilePreviewComponent } from './components/project-details/documents-list/file-preview/file-preview.component';
import { SimpleSelectorComponent } from './components/sub-header/simple-selector/simple-selector.component';
import { AreaWeightSettingsFormComponent } from './components/project-details/project-progress/area-weight-settings-form/area-weight-settings-form.component';
import { AreaWeightSelectorComponent } from './components/project-details/project-progress/area-form/area-weight-selector/area-weight-selector.component';
import { ActivityDateFiltersComponent } from './components/project-details/activity-project-table/activity-date-filters/activity-date-filters.component';
import { AreasListComponent } from './components/project-details/areas-list/areas-list.component';
import { ProjectStatusSelectorComponent } from './components/project-details/areas-list/project-status-selector/project-status-selector.component';
import { FloorsSelectorComponent } from './components/project-details/areas-list/floors-selector/floors-selector.component';
import { AreaDetailsPageComponent } from './components/project-details/area-details-page/area-details-page.component';
import { AreaDetailsSectionComponent } from './components/project-details/project-progress/area-form/area-details-section/area-details-section.component';
import { AreaDetailsHeaderComponent } from './components/project-details/project-progress/area-form/area-details-section/area-details-header/area-details-header.component';
import { ProjectDetailsTabsComponent } from './components/project-details/project-details-tabs/project-details-tabs.component';

@NgModule({
    declarations: [
        DashboardComponent,
        SystemHomeComponent,
        SidebarComponent,
        CompaniesComponent,
        UsersComponent,
        SubHeaderComponent,
        FilterFieldsComponent,
        ProjectsComponent,
        CustomDatapickerComponent,
        ProjectFormComponent,
        NotificationComponent,
        CompanyFormComponent,
        InviteUserFormComponent,
        ProjectDetailsComponent,
        CompanySettingsComponent,
        OutsideClickDirective,
        IsLowerThenMiddleOfScreen,
        VarDirective,
        IsRighterThenMiddleOfScreenDirective,
        UserProfileComponent,
        SystemErrorPageComponent,
        RedirectComponent,
        UserProfileFormComponent,
        HomeRedirectComponent,
        ChangePasswordFormComponent,
        ProjectSettingsComponent,
        AlphanumericDirective,
        StopClickPropagationDirective,
        FormInputDirective,
        LoaderDirective,
        FileDropAreaDirective,
        FirstInputAutofocusOnMobileDirective,
        AutoScrollbarDirective,
        ScrollToFirstInvalidInputDirective,
        SpriteVersionDirective,
        AddUsersFormComponent,
        PhonePrettifyPipe,
        FileSizePrettifyPipe,
        RoomNamePipe,
        UrlifyDirective,
        SliceTextPipe,
        TasksComponent,
        TaskFormComponent,
        PaginationComponent,
        ProjectViewComponent,
        RoomFormComponent,
        FloorsSwiperComponent,
        UnitBlockComponent,
        RoomBlockComponent,
        ProjectProgressComponent,
        ViewAreaStatusComponent,
        AreaFormComponent,
        LoaderComponent,
        ActivityAreaComponent,
        AreaEditorComponent,
        HistoryAreaComponent,
        FilesAreaComponent,
        FiltersProjectProgressComponent,
        StatusColorPickerComponent,
        AutoTrimDirective,
        TimePrettifyPipe,
        DateFormatterPipe,
        SetLinkToNotification,
        VisibilityObserverDirective,
        LoaderProfileComponent,
        LoaderUnitsComponent,
        LoaderProjectProgressComponent,
        DeletingStatusModalComponent,
        DashboardCompaniesTableComponent,
        DashboardProjectsTableComponent,
        DashboardTasksTableComponent,
        DashboardActivityTableComponent,
        ActivityProjectTableComponent,
        DeletingStatusModalComponent,
        FloorsListComponent,
        UnitsListComponent,
        RoomsListComponent,
        EmailNotificationSettingsComponent,
        FloorsSwiperElComponent,
        ProjectMaterialsComponent,
        ProjectMaterialFormComponent,
        MaterialCustomSelectorComponent,
        MaterialCustomSelectorWithColoredOptionsComponent,
        AddFilesToFormComponent,
        CountriesCodeSelectComponent,
        IsLowerThenMiddleOfContainerDirective,
        DesignSettingsComponent,
        AreaAddFilesComponent,
        DocumentsListComponent,
        CustomSelectComponent,
        DocumentFormComponent,
        DocumentsTypeIconDirective,
        DrawingsListComponent,
        DrawingFormComponent,
        FilePreviewComponent,
        SimpleSelectorComponent,
        AreaWeightSettingsFormComponent,
        AreaWeightSelectorComponent,
        ActivityDateFiltersComponent,
        AreasListComponent,
        ProjectStatusSelectorComponent,
        FloorsSelectorComponent,
        AreaDetailsPageComponent,
        AreaDetailsSectionComponent,
        AreaDetailsHeaderComponent,
        ProjectDetailsTabsComponent,
    ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    NgxSelectModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    NgSelectModule,
    NgxMaskDirective,
    EllipsisModule,
    NgxMaskPipe,
    SwiperModule,
    DragDropModule,
    NgToggleModule.forRoot(),
    QuillModule.forRoot(),
    NgScrollbarModule,
    NestedEllipsisModule,
    NgxDocViewerModule
  ],
  exports: [
    NotificationComponent,
    AlphanumericDirective,
    FormInputDirective,
    LoaderDirective,
    AutoTrimDirective,
    TimePrettifyPipe,
    DateFormatterPipe,
    SetLinkToNotification,
    VisibilityObserverDirective,
    SpriteVersionDirective,
    OutsideClickDirective,
    CountriesCodeSelectComponent,
  ],
    providers: [
        provideEnvironmentNgxMask(),
        DatePipe,
        RoomNamePipe,
        IsRighterThenMiddleOfScreenDirective
    ]
})
export class AdminModule {
}
